import React, { Component } from 'react';
import WithStorage from './WithStorage';


function withStorageWrapper(WrappedComponent) {
  return class withStorage extends Component {
    render() {
      return (
        <WithStorage
          WrappedComponent={WrappedComponent}
          {...this.props}
        />
      );
    }
  }
}

export default withStorageWrapper;
