import React, { forwardRef } from 'react';
import { Grid } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import { Button } from 'components';

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
  >
    <RouterLink {...props} />
  </div>
));

const BottomPageNavigation = ({ prevTitle, nextTitle, prev, next }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          {prev && (
            <Button
              to={prev}
              component={CustomRouterLink}
            >
              {prevTitle}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          {next && (
            <Button
              to={next}
              component={CustomRouterLink}
            >
              {nextTitle}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BottomPageNavigation;
