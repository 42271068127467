import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  colors
} from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

const useStyles = makeStyles(theme => ({
  root: {},
  media: {
    height: 240
  },
  placeholder: {
    height: 240,
    backgroundColor: colors.blueGrey[50],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  insertDriveFileIcon: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    fontSize: theme.spacing(6)
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  actions: {
    justifyContent: 'center'
  },
  getAppIcon: {
    marignRight: theme.spacing(1)
  },
  menu: {
    width: 250,
    maxWidth: '100%'
  }
}));

const FileCard = props => {
  const { file, className, actions } = props;

  const classes = useStyles();

  const moreRef = useRef(null);

  const [openMenu, setOpenMenu] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);

  actions.getDownloadUrl(file.key).then((url) => {
    setDownloadUrl(url);
  });

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleDelete = () => {
    const { actions, file } = props;
    const { deleteFile } = actions;
    deleteFile(file);
  };

  const fileName = file.key.substring(file.key.indexOf('/') + 1);

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      
      <div className={classes.placeholder}>
        <InsertDriveFileIcon className={classes.insertDriveFileIcon} />
      </div>
      <CardContent className={classes.content}>
        <div>
          <Typography variant="h5">{fileName}</Typography>
          <Typography variant="subtitle2">{file.size}</Typography>
        </div>
        <div>
          <Tooltip title="More options">
            <IconButton
              edge="end"
              onClick={handleMenuOpen}
              ref={moreRef}
              size="small"
            >
              <MoreIcon />
            </IconButton>
          </Tooltip>
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        {downloadUrl && (
          <Button
            href={downloadUrl}
            target="_blank"
            rel="noreferrer"
          >
            <GetAppIcon className={classes.getAppIcon} />
            Download
          </Button>
        )}
      </CardActions>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{ paper: classes.menu }}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          divider
          onClick={handleDelete}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
    </Card>
  );
};

FileCard.propTypes = {
  className: PropTypes.string,
  file: PropTypes.object.isRequired
};

export default FileCard;
