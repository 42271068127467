import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  TextField,
  FormLabel
} from '@material-ui/core';
import { DatePicker } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  formGroup: {
    marginBottom: theme.spacing(3)
  }
}));

const getOptions = (lookup) => {
  const options = Object.keys(lookup);
  return options.map(option => (
    <option
      key={option}
      value={option}
    >
      {lookup[option]}
    </option>
  ));
} 

const EditDataListItem = ({ column, values, handleFieldChange }) => {
  const classes = useStyles();
  
  return (
    <div className={classes.formGroup}>
      {column.lookup && (
        <>
          {column.useLabel && (
            <FormLabel>{column.title}</FormLabel>
          )}
          <TextField
            fullWidth
            label={!column.useLabel ? column.title : null}
            onChange={event =>
              handleFieldChange(event, column.field, event.target.value)
            }
            select
            // eslint-disable-next-line react/jsx-sort-props
            SelectProps={{ native: true }}
            value={values[column.field] || ''}
            variant="outlined"
          >
            {getOptions(column.lookup)}
          </TextField>
        </>
      )}
      {column.dateFormat && (
        <>
          {column.useLabel && (
            <FormLabel>{column.title}</FormLabel>
          )}
          <DatePicker
            label={!column.useLabel ? column.title : null}
            dateFormat={column.dateFormat}
            value={values[column.field] || new Date()}
            onChange={date => handleFieldChange(undefined, column.field, date)} 
          />
        </>
      )}
      {!column.lookup && !column.dateFormat && (
        <TextField
          fullWidth
          label={column.title}
          name={column.field}
          onChange={event =>
            handleFieldChange(event, column.field, event.target.value)
          }
          value={values[column.field] || ''}
          variant="outlined"
        />
      )}
    </div>
  );
}

export default EditDataListItem;
