import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  IconButton,
  Tooltip
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import DataListCardContent from '../DataListCardContent';

const useStyles = makeStyles(theme => ({
  root: {},
  dataGroup: {
    marginBottom: theme.spacing(1)
  },
  heading: {
    marginBottom: theme.spacing(1)
  },
  body: {
    marginBottom: theme.spacing(1)
  }
}));

const DataListCard = props => {
  const classes = useStyles();
  
  const { id, title, columns, data, editable, detailPanel, localization} = props;
  const {body: {editTooltip, deleteTooltip, editRow: {cancelTooltip, saveTooltip}}} = localization;
  const [edit, setEdit] = React.useState(false);
  const [values, setValues] = useState(data);

  const handleFieldChange = (event, field, value) => {
    event && event.persist && event.persist();
    setValues(values => ({
      ...values,
      [field]: value
    }));
  };

  const onClickEdit = () => {
    setEdit(true);
  }

  const onClickCancel = () => {
    setEdit(false);
  }

  const onClickSave = () => {
    editable.onRowUpdate(values);
    setEdit(false);
  }

  const onClickDelete = () => {
    editable.onRowDelete(data);
  }

  const isEditable = typeof editable !== 'undefined';
  const canUpdate = isEditable && typeof editable.onRowUpdate !== 'undefined';
  const canDelete = isEditable && typeof editable.onRowDelete !== 'undefined';
  return (
    <Card
      className={clsx(classes.root)}
    >
      {title && (
        <CardHeader
          title={title}
        />
      )}
      <DataListCardContent
        id={id}
        edit={edit}
        columns={columns}
        data={data}
        values={values}
        handleFieldChange={handleFieldChange}
        detailPanel={detailPanel}
      />
      <CardActions disableSpacing>
        {canUpdate && !edit && (
          <Tooltip title={editTooltip}>
            <IconButton
              onClick={onClickEdit}
              aria-label={editTooltip}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {canUpdate && edit && (
          <Tooltip title={saveTooltip}>
            <IconButton
              onClick={onClickSave}
              aria-label={saveTooltip}
            >
              <CheckIcon />
            </IconButton>
          </Tooltip>
        )}
        {canUpdate && edit && (
          <Tooltip title={cancelTooltip}>
            <IconButton
              onClick={onClickCancel}
              aria-label={cancelTooltip}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        )}
        {canDelete && !edit && (
          <Tooltip title={deleteTooltip}>
            <IconButton
              onClick={onClickDelete}
              aria-label={deleteTooltip}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
};

DataListCard.propTypes = {
  className: PropTypes.string
};

export default DataListCard;
