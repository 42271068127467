import React from 'react';
import { WithContent } from 'components';
import Example from './Example';


const ExampleWithContent = (props) => {
  const { columns, ...rest } = props;
  return (
    <WithContent
      {...rest}
      id={columns}
      Component={Example}
    />
  );
}

export default ExampleWithContent;
