import React from 'react';
import { Button as MaterialUiButton } from '@material-ui/core';
import { useParams } from 'react-router-dom';

const Button = (props) => {
  const { locale } = useParams();
  const { href, to, locale: localeProp = locale } = props;

  const extraProps = {}

  if (typeof href !== 'undefined') {
    extraProps.href= `/${localeProp}${href}`
  }

  if (typeof to !== 'undefined') {
    extraProps.to= `/${localeProp}${to}`
  }

  return (
    <MaterialUiButton
      {...props}
      {...extraProps}
    />
  );
}

export default Button;
