import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Example, WithContent, Markdown } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const Question = (props) => {
  const { title, description, example, markdown } = props;
  const classes = useStyles();
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography 
          className={classes.heading}
          variant="h6"
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {description && (
          <Typography>{description}</Typography>
        )}
        {example && (
          <WithContent
            id={example}
            Component={Example}
          />
        )}
        {markdown && (
          <WithContent
            id={markdown}
            Component={Markdown}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const Questions = (props) => {
  const classes = useStyles();

  const { questions } = props;
  return (
    <div className={classes.root}>
      {questions && questions.map((question, index) => {
        return <Question {...question} key={`question-${index}`}/>
      })}
    </div>
  );
};

export default Questions;
