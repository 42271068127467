import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  IconButton,
  Tooltip,
  Toolbar,
  Typography,
  Paper
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ViewListIcon from '@material-ui/icons/ViewList';
import TableChartIcon from '@material-ui/icons/TableChart';
import Sort from './Sort';
import { Markdown } from 'components';
import useContent from 'hooks/useContent';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5)
  },
  spacer: {
    flex: '1 1 10%'
  },
  toolbar: {
    paddingRight: '8px'
  },
  button: {
    marginTop: '12px'
  },
  contents: {
    padding: theme.spacing(2)
  }
}));

export const ChangeViewButton = (props) => {
  const classes = useStyles();
  const { currentView, onClickChangeView } = props;
  const CurrentViewIcon = currentView === 'list' ? TableChartIcon : ViewListIcon;
  const { tableViewLabel, listViewLabel } = useContent('labels.html');

  return (
    <Tooltip title={currentView === 'list' ? tableViewLabel : listViewLabel}>
      <IconButton
        className={classes.button}
        onClick={onClickChangeView}
        aria-label={currentView === 'list' ? tableViewLabel : listViewLabel}
      >
        <CurrentViewIcon />
      </IconButton>
    </Tooltip>
  );
}

const Actions = (props) => {
  const classes = useStyles();
  const { onClickAdd, onClickChangeView, onSort, options = {}, localization = {} } = props;
  const { listView, tableView } = options;
  const {body: {addTooltip} = {}} = localization;

  return (
    <Fragment>
      {onSort && (
        <Sort {...props} />
      )}
      {onClickAdd && (
        <Tooltip title={addTooltip}>
          <IconButton
            className={classes.button}
            onClick={onClickAdd}
            aria-label={addTooltip}
          >
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      )}
      {onClickChangeView && listView && tableView && (
        <ChangeViewButton {...props} />
      )}
    </Fragment>
  );
}

const DataListHeader = (props) => {
  const classes = useStyles();
  const { title, contents } = props;

  return (
    <Paper
      className={classes.root}
      elevation={2}
    >
      <Toolbar
        className={classes.toolbar}
      >
        <div>
          <Typography
            component="h6"
            variant="h6"
          >
            {title}
          </Typography>
        </div>
        <div className={classes.spacer}></div>
        <div>
          <Actions
            {...props}
          />
        </div>
      </Toolbar>
      {contents && (
        <div className={classes.contents}>
          <Markdown contents={contents} />
        </div>
      )}
    </Paper>
  );
}

export default DataListHeader;
