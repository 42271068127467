import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { Button, Markdown } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '60px',
    borderRadius: '6px'
  },
  preheading: {
    background: '#ffffff',
    marginBottom: '10px',
    padding: '5px 40px',
    textTransform: 'uppercase',
    fontSize: '32px',
    lineHeight: '32px',
    fontWeight: '700'
  },
  heading: {
    background: '#ffffff',
    color: '#000000',
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(60),
    fontWeight: '700',
    lineHeight: '60px',
    marginBottom: '10px',
    padding: '5px 40px'
  },
  bigText: {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: theme.typography.fontFamily,
    marginBottom: '10px',
    backgroundColor: '#ffffff',
    padding: '21px 40px 5px 40px'
  },
  button: {
    marginRight: '5px'
  }
}));

const IntroductionCard = (props) => {
  const classes = useStyles();
  const { heading, preheading, contents, buttons, backgroundColor } = props;

  return (
    <div
      className={clsx({
        [classes.root]: true
      })}
      data-test="introduction-card"
      style={{
        backgroundColor
      }}
    >
      {preheading && (
        <Typography
          className={clsx({
            [classes.preheading]: true
          })}
          data-test="introduction-card__pre-title"
          style={{
            color: backgroundColor
          }}
        >
          {preheading}
        </Typography>
      )}
      {heading && (
        <Typography
          className={clsx({
            [classes.heading]: true
          })}
          data-test="introduction-card__heading"
        >
          {heading}
        </Typography>
      )}
      <Markdown
        className={clsx(classes.bigText)}
        contents={contents}
      />
      {buttons && buttons.map((button, index) => {
        return <Button
          {...button}
          key={`introduction-button-${index}`}
          variant="contained"
          className={clsx({
            [classes.button]: true
          })}
          data-test="introduction-card__button"
        />
      })}
    </div>
  );
};

export default IntroductionCard;
