import { Auth } from 'aws-amplify';

export default async function getOwnerAndRegion() {
  const user = await Auth.currentAuthenticatedUser();
  const owner = user.username;
  const region = user.signInUserSession.accessToken.payload['cognito:groups'][0];
  const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
  const jwtToken = user.signInUserSession.accessToken.jwtToken;
  return { owner, region, groups, user, jwtToken };
}

export async function signOut() {
  await Auth.signOut();
}
