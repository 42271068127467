import React from 'react';
import Chart from "react-apexcharts";

const RadarChart = (props) => {
  const data = {
    series: [{
      name: 'Series 1',
      data: [80, 50, 30, 40, 100, 20],
    }],
    options: {
      chart: {
        type: 'radar',
      },
      title: {
        text: 'Basic Radar Chart'
      },
      xaxis: {
        categories: ['January', 'February', 'March', 'April', 'May', 'June']
      }
    },
    type: 'radar',
    ...props
  };

  return (
    <Chart {...data} />
  )
}

export default RadarChart;
