import React from 'react';
import { WithContent } from 'components';
import SignOut from './SignOut';


const SignOutWithContent = (props) => {
  return (
    <WithContent
      id='authentication/sign-out.html'
      Component={SignOut}
      {...props} 
    />
  );
}

export default SignOutWithContent;
