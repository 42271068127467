import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import { renderRoutes } from 'react-router-config';
import { Notifications } from 'components';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import routes from './Routes';

const browserHistory = createBrowserHistory();

Amplify.configure(awsconfig);

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter history={browserHistory}>
          {renderRoutes(routes)}
        </BrowserRouter>
        <Notifications />
      </ThemeProvider>
    );
  }
}

export default App;
