import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid
} from '@material-ui/core';
import { Markdown, Files } from 'components';

const FilesCard = (props) => {
  const { title, contents, folder } = props;

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <Card>
          {title && (
            <CardHeader
              title={title}
            />
          )}
          <CardContent>
            {contents && (
              <Markdown contents={contents} />
            )}
            {folder && (
              <Files folder={folder} />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default FilesCard;
