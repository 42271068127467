import React, { forwardRef } from 'react';
import { NavLink as RouterLink, useLocation, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';
import { Button } from 'components';
import * as icons from 'icons';

const useStyles = makeStyles(theme => ({
  root: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    '&.active': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      '& $icon': {
        color: theme.palette.primary.main
      }
    }
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const IconWrapper = props => {
  const classes = useStyles();
  const Icon = icons[props.icon];
  return (
    <div className={classes.icon}><Icon /></div>
  );
}

const SidebarNavButton = props => {
  const classes = useStyles();
  const { year } = useParams();
  
  const href = props.href.indexOf('%year%') > -1 ? props.href.replace('%year%', year) : props.href;
  
  return (
    <Button
      className={clsx([
        'sidebar-nav__button',
        classes.root
      ])}
      component={CustomRouterLink}
      to={href}
    >
      {props.icon && (
        <IconWrapper icon={props.icon} />
      )}
      {props.title}
    </Button>
  );
};

export default SidebarNavButton;
