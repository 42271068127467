/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStakeholder = /* GraphQL */ `
  query GetStakeholder($id: ID!) {
    getStakeholder(id: $id) {
      id
      name
      title
      organization
      email
      phone
      rationale
      onTeam
      ifNotOnTeam
      comment
      accepted
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listStakeholders = /* GraphQL */ `
  query ListStakeholders(
    $filter: ModelStakeholderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStakeholders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        title
        organization
        email
        phone
        rationale
        onTeam
        ifNotOnTeam
        comment
        accepted
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      mandate
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mandate
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getTeamLeader = /* GraphQL */ `
  query GetTeamLeader($id: ID!) {
    getTeamLeader(id: $id) {
      id
      name
      title
      organization
      contact
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listTeamLeaders = /* GraphQL */ `
  query ListTeamLeaders(
    $filter: ModelTeamLeaderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamLeaders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        title
        organization
        contact
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getTeamChampion = /* GraphQL */ `
  query GetTeamChampion($id: ID!) {
    getTeamChampion(id: $id) {
      id
      name
      title
      organization
      contact
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listTeamChampions = /* GraphQL */ `
  query ListTeamChampions(
    $filter: ModelTeamChampionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamChampions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        title
        organization
        contact
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getWeatherHazard = /* GraphQL */ `
  query GetWeatherHazard($id: ID!) {
    getWeatherHazard(id: $id) {
      id
      description
      date
      effects
      source
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listWeatherHazards = /* GraphQL */ `
  query ListWeatherHazards(
    $filter: ModelWeatherHazardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeatherHazards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        date
        effects
        source
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      name
      type
      year
      relevance
      link
      notes
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        year
        relevance
        link
        notes
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getCommunication = /* GraphQL */ `
  query GetCommunication($id: ID!) {
    getCommunication(id: $id) {
      id
      stakeholder
      organization
      contact
      medium
      timing
      notes
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listCommunications = /* GraphQL */ `
  query ListCommunications(
    $filter: ModelCommunicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stakeholder
        organization
        contact
        medium
        timing
        notes
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getClimaticChange = /* GraphQL */ `
  query GetClimaticChange($id: ID!) {
    getClimaticChange(id: $id) {
      id
      platform
      model
      emissionsScenario
      baselineYear
      timeframe
      seasonalVariability
      missingData
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listClimaticChanges = /* GraphQL */ `
  query ListClimaticChanges(
    $filter: ModelClimaticChangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClimaticChanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        platform
        model
        emissionsScenario
        baselineYear
        timeframe
        seasonalVariability
        missingData
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getAdaptationAction = /* GraphQL */ `
  query GetAdaptationAction($id: ID!) {
    getAdaptationAction(id: $id) {
      id
      climateImpact {
        id
        climateConditionId
        outcome
        consequence
        statement
        affectedAreas
        sensitivity
        sensitivityJustification
        adaptiveCapacity
        adaptiveCapacityJustification
        carryForward
        type
        likelihood
        likelihoodJustification
        publicHealthConsequence
        displacementConsequence
        lossOfLivelihoodConsequence
        culturalAspectsConsequence
        socialConsequenceRiskScore
        socialConsequenceRiskLevel
        propertyDamageConsequence
        localEconomyConsequence
        communityLivabilityConsequence
        publicAdministrationConsequence
        economicConsequenceRiskScore
        economicConsequenceRiskLevel
        airConsequence
        waterConsequence
        soilAndVegetationConsequence
        ecosytemFunctionConsequence
        naturalConsequenceRiskScore
        naturalConsequenceRiskLevel
        totalRisk
        totalRiskScore
        totalRiskLevel
        vulnerabilityRanking
        theme {
          id
          name
          updatedAt
          createdAt
          owner
          region
        }
        adaptationActions {
          nextToken
        }
        objective
        requiredInformation
        target
        includeInPlan
        updatedAt
        createdAt
        owner
        region
      }
      indicators {
        items {
          id
          name
          willInformBaseline
          requiredData
          dataExists
          responsibleParty
          nextSteps
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      adaptationAction
      leadDepartment
      supportingDepartment
      justification
      includeInPlan
      scope
      supportingActions {
        items {
          id
          action
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      timeline
      costs
      additionalResources
      funding
      notes
      status
      implementedBy
      actualCosts
      monitoringNotes
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listAdaptationActions = /* GraphQL */ `
  query ListAdaptationActions(
    $filter: ModelAdaptationActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdaptationActions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        climateImpact {
          id
          climateConditionId
          outcome
          consequence
          statement
          affectedAreas
          sensitivity
          sensitivityJustification
          adaptiveCapacity
          adaptiveCapacityJustification
          carryForward
          type
          likelihood
          likelihoodJustification
          publicHealthConsequence
          displacementConsequence
          lossOfLivelihoodConsequence
          culturalAspectsConsequence
          socialConsequenceRiskScore
          socialConsequenceRiskLevel
          propertyDamageConsequence
          localEconomyConsequence
          communityLivabilityConsequence
          publicAdministrationConsequence
          economicConsequenceRiskScore
          economicConsequenceRiskLevel
          airConsequence
          waterConsequence
          soilAndVegetationConsequence
          ecosytemFunctionConsequence
          naturalConsequenceRiskScore
          naturalConsequenceRiskLevel
          totalRisk
          totalRiskScore
          totalRiskLevel
          vulnerabilityRanking
          objective
          requiredInformation
          target
          includeInPlan
          updatedAt
          createdAt
          owner
          region
        }
        indicators {
          nextToken
        }
        adaptationAction
        leadDepartment
        supportingDepartment
        justification
        includeInPlan
        scope
        supportingActions {
          nextToken
        }
        timeline
        costs
        additionalResources
        funding
        notes
        status
        implementedBy
        actualCosts
        monitoringNotes
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getClimateImpact = /* GraphQL */ `
  query GetClimateImpact($id: ID!) {
    getClimateImpact(id: $id) {
      id
      climateConditionId
      outcome
      consequence
      statement
      affectedAreas
      sensitivity
      sensitivityJustification
      adaptiveCapacity
      adaptiveCapacityJustification
      carryForward
      type
      likelihood
      likelihoodJustification
      publicHealthConsequence
      displacementConsequence
      lossOfLivelihoodConsequence
      culturalAspectsConsequence
      socialConsequenceRiskScore
      socialConsequenceRiskLevel
      propertyDamageConsequence
      localEconomyConsequence
      communityLivabilityConsequence
      publicAdministrationConsequence
      economicConsequenceRiskScore
      economicConsequenceRiskLevel
      airConsequence
      waterConsequence
      soilAndVegetationConsequence
      ecosytemFunctionConsequence
      naturalConsequenceRiskScore
      naturalConsequenceRiskLevel
      totalRisk
      totalRiskScore
      totalRiskLevel
      vulnerabilityRanking
      theme {
        id
        name
        climateImpacts {
          nextToken
        }
        updatedAt
        createdAt
        owner
        region
      }
      adaptationActions {
        items {
          id
          adaptationAction
          leadDepartment
          supportingDepartment
          justification
          includeInPlan
          scope
          timeline
          costs
          additionalResources
          funding
          notes
          status
          implementedBy
          actualCosts
          monitoringNotes
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      objective
      requiredInformation
      target
      includeInPlan
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listClimateImpacts = /* GraphQL */ `
  query ListClimateImpacts(
    $filter: ModelClimateImpactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClimateImpacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        climateConditionId
        outcome
        consequence
        statement
        affectedAreas
        sensitivity
        sensitivityJustification
        adaptiveCapacity
        adaptiveCapacityJustification
        carryForward
        type
        likelihood
        likelihoodJustification
        publicHealthConsequence
        displacementConsequence
        lossOfLivelihoodConsequence
        culturalAspectsConsequence
        socialConsequenceRiskScore
        socialConsequenceRiskLevel
        propertyDamageConsequence
        localEconomyConsequence
        communityLivabilityConsequence
        publicAdministrationConsequence
        economicConsequenceRiskScore
        economicConsequenceRiskLevel
        airConsequence
        waterConsequence
        soilAndVegetationConsequence
        ecosytemFunctionConsequence
        naturalConsequenceRiskScore
        naturalConsequenceRiskLevel
        totalRisk
        totalRiskScore
        totalRiskLevel
        vulnerabilityRanking
        theme {
          id
          name
          updatedAt
          createdAt
          owner
          region
        }
        adaptationActions {
          nextToken
        }
        objective
        requiredInformation
        target
        includeInPlan
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getVision = /* GraphQL */ `
  query GetVision($id: ID!) {
    getVision(id: $id) {
      id
      vision
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listVisions = /* GraphQL */ `
  query ListVisions(
    $filter: ModelVisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vision
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getTheme = /* GraphQL */ `
  query GetTheme($id: ID!) {
    getTheme(id: $id) {
      id
      name
      climateImpacts {
        items {
          id
          climateConditionId
          outcome
          consequence
          statement
          affectedAreas
          sensitivity
          sensitivityJustification
          adaptiveCapacity
          adaptiveCapacityJustification
          carryForward
          type
          likelihood
          likelihoodJustification
          publicHealthConsequence
          displacementConsequence
          lossOfLivelihoodConsequence
          culturalAspectsConsequence
          socialConsequenceRiskScore
          socialConsequenceRiskLevel
          propertyDamageConsequence
          localEconomyConsequence
          communityLivabilityConsequence
          publicAdministrationConsequence
          economicConsequenceRiskScore
          economicConsequenceRiskLevel
          airConsequence
          waterConsequence
          soilAndVegetationConsequence
          ecosytemFunctionConsequence
          naturalConsequenceRiskScore
          naturalConsequenceRiskLevel
          totalRisk
          totalRiskScore
          totalRiskLevel
          vulnerabilityRanking
          objective
          requiredInformation
          target
          includeInPlan
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listThemes = /* GraphQL */ `
  query ListThemes(
    $filter: ModelThemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThemes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        climateImpacts {
          nextToken
        }
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getSupportingAction = /* GraphQL */ `
  query GetSupportingAction($id: ID!) {
    getSupportingAction(id: $id) {
      id
      adaptationAction {
        id
        climateImpact {
          id
          climateConditionId
          outcome
          consequence
          statement
          affectedAreas
          sensitivity
          sensitivityJustification
          adaptiveCapacity
          adaptiveCapacityJustification
          carryForward
          type
          likelihood
          likelihoodJustification
          publicHealthConsequence
          displacementConsequence
          lossOfLivelihoodConsequence
          culturalAspectsConsequence
          socialConsequenceRiskScore
          socialConsequenceRiskLevel
          propertyDamageConsequence
          localEconomyConsequence
          communityLivabilityConsequence
          publicAdministrationConsequence
          economicConsequenceRiskScore
          economicConsequenceRiskLevel
          airConsequence
          waterConsequence
          soilAndVegetationConsequence
          ecosytemFunctionConsequence
          naturalConsequenceRiskScore
          naturalConsequenceRiskLevel
          totalRisk
          totalRiskScore
          totalRiskLevel
          vulnerabilityRanking
          objective
          requiredInformation
          target
          includeInPlan
          updatedAt
          createdAt
          owner
          region
        }
        indicators {
          nextToken
        }
        adaptationAction
        leadDepartment
        supportingDepartment
        justification
        includeInPlan
        scope
        supportingActions {
          nextToken
        }
        timeline
        costs
        additionalResources
        funding
        notes
        status
        implementedBy
        actualCosts
        monitoringNotes
        updatedAt
        createdAt
        owner
        region
      }
      action
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listSupportingActions = /* GraphQL */ `
  query ListSupportingActions(
    $filter: ModelSupportingActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupportingActions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        adaptationAction {
          id
          adaptationAction
          leadDepartment
          supportingDepartment
          justification
          includeInPlan
          scope
          timeline
          costs
          additionalResources
          funding
          notes
          status
          implementedBy
          actualCosts
          monitoringNotes
          updatedAt
          createdAt
          owner
          region
        }
        action
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getIndicator = /* GraphQL */ `
  query GetIndicator($id: ID!) {
    getIndicator(id: $id) {
      id
      adaptationAction {
        id
        climateImpact {
          id
          climateConditionId
          outcome
          consequence
          statement
          affectedAreas
          sensitivity
          sensitivityJustification
          adaptiveCapacity
          adaptiveCapacityJustification
          carryForward
          type
          likelihood
          likelihoodJustification
          publicHealthConsequence
          displacementConsequence
          lossOfLivelihoodConsequence
          culturalAspectsConsequence
          socialConsequenceRiskScore
          socialConsequenceRiskLevel
          propertyDamageConsequence
          localEconomyConsequence
          communityLivabilityConsequence
          publicAdministrationConsequence
          economicConsequenceRiskScore
          economicConsequenceRiskLevel
          airConsequence
          waterConsequence
          soilAndVegetationConsequence
          ecosytemFunctionConsequence
          naturalConsequenceRiskScore
          naturalConsequenceRiskLevel
          totalRisk
          totalRiskScore
          totalRiskLevel
          vulnerabilityRanking
          objective
          requiredInformation
          target
          includeInPlan
          updatedAt
          createdAt
          owner
          region
        }
        indicators {
          nextToken
        }
        adaptationAction
        leadDepartment
        supportingDepartment
        justification
        includeInPlan
        scope
        supportingActions {
          nextToken
        }
        timeline
        costs
        additionalResources
        funding
        notes
        status
        implementedBy
        actualCosts
        monitoringNotes
        updatedAt
        createdAt
        owner
        region
      }
      name
      willInformBaseline
      requiredData
      dataExists
      responsibleParty
      nextSteps
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listIndicators = /* GraphQL */ `
  query ListIndicators(
    $filter: ModelIndicatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIndicators(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        adaptationAction {
          id
          adaptationAction
          leadDepartment
          supportingDepartment
          justification
          includeInPlan
          scope
          timeline
          costs
          additionalResources
          funding
          notes
          status
          implementedBy
          actualCosts
          monitoringNotes
          updatedAt
          createdAt
          owner
          region
        }
        name
        willInformBaseline
        requiredData
        dataExists
        responsibleParty
        nextSteps
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      name
      size
      folder
      key
      type
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        size
        folder
        key
        type
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getImplementationSurvey = /* GraphQL */ `
  query GetImplementationSurvey($id: ID!) {
    getImplementationSurvey(id: $id) {
      id
      factor1
      factor2
      factor3
      factor4
      factor5
      factor6
      factor7
      factor8
      factor9
      factor10
      factor11
      factor12
      factor13
      factor14
      factor15
      factor16
      factor17
      factor18
      factor19
      factor20
      factor21
      factor22
      factor23
      factor24
      factor25
      localActionPlanTotal
      communityContextTotal
      managementAndGovernanceTotal
      staffAndFinancialResourcesTotal
      stakeholderManagementTotal
      version
      dateCompleted
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listImplementationSurveys = /* GraphQL */ `
  query ListImplementationSurveys(
    $filter: ModelImplementationSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImplementationSurveys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        factor1
        factor2
        factor3
        factor4
        factor5
        factor6
        factor7
        factor8
        factor9
        factor10
        factor11
        factor12
        factor13
        factor14
        factor15
        factor16
        factor17
        factor18
        factor19
        factor20
        factor21
        factor22
        factor23
        factor24
        factor25
        localActionPlanTotal
        communityContextTotal
        managementAndGovernanceTotal
        staffAndFinancialResourcesTotal
        stakeholderManagementTotal
        version
        dateCompleted
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getAccomplishment = /* GraphQL */ `
  query GetAccomplishment($id: ID!) {
    getAccomplishment(id: $id) {
      id
      stakeholder
      communication
      instrument
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listAccomplishments = /* GraphQL */ `
  query ListAccomplishments(
    $filter: ModelAccomplishmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccomplishments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stakeholder
        communication
        instrument
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      firstName
      lastName
      phone
      updatedAt
      createdAt
      owner
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        phone
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const getRegion = /* GraphQL */ `
  query GetRegion($id: ID!) {
    getRegion(id: $id) {
      id
      name
      population
      latitude
      longitude
      gcomId
      gcomOptIn
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listRegions = /* GraphQL */ `
  query ListRegions(
    $filter: ModelRegionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        population
        latitude
        longitude
        gcomId
        gcomOptIn
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getAnnualReportQuestion = /* GraphQL */ `
  query GetAnnualReportQuestion($id: ID!) {
    getAnnualReportQuestion(id: $id) {
      id
      year
      questionNumber
      columnNumber
      rowNumber
      answer
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listAnnualReportQuestions = /* GraphQL */ `
  query ListAnnualReportQuestions(
    $filter: ModelAnnualReportQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnnualReportQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        year
        questionNumber
        columnNumber
        rowNumber
        answer
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getAnnualReportStatus = /* GraphQL */ `
  query GetAnnualReportStatus($id: ID!) {
    getAnnualReportStatus(id: $id) {
      id
      year
      status
      confirmation
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listAnnualReportStatuss = /* GraphQL */ `
  query ListAnnualReportStatuss(
    $filter: ModelAnnualReportStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnnualReportStatuss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        year
        status
        confirmation
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
export const getBadgeStatus = /* GraphQL */ `
  query GetBadgeStatus($id: ID!) {
    getBadgeStatus(id: $id) {
      id
      year
      name
      status
      confirmation
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const listBadgeStatuss = /* GraphQL */ `
  query ListBadgeStatuss(
    $filter: ModelBadgeStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBadgeStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        year
        name
        status
        confirmation
        updatedAt
        createdAt
        owner
        region
      }
      nextToken
    }
  }
`;
