/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Typography } from '@material-ui/core';
import SidebarNavButton from '../SidebarNavButton';
import { UserContext } from 'hooks/userContext';

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  heading: {
    textTransform: 'uppercase'
  },
  milestones: {
    color: 'red'
  }
}));

const SidebarNav = props => {
  const { pages, className, heading, ...rest } = props;
  
  const context = useContext(UserContext);
  const { regionData } = context;

  const filteredPages = pages.filter(page => {
    let include = true;
    if (page.showIf) {
      include = regionData && !!regionData[page.showIf];
    }
    return include;
  });

  const classes = useStyles();
  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.heading}>
        <Typography variant="overline">{heading}</Typography>
      </div>
      {filteredPages.map(page => {
        return (
          <ListItem
            className={classes.item}
            disableGutters
            key={page.title}
          >
            <SidebarNavButton {...page} />
          </ListItem>
        );
      })}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
