import React from 'react';
import { useParams } from 'react-router-dom';
import { WithContent } from 'components';
import Sidebar from './Sidebar';


const SidebarWithContent = (props) => {
  const { milestone } = useParams();
  return (
    <WithContent
      id={`sidebar/${milestone}.html`}
      Component={Sidebar}
      {...props} 
    />
  );
}

export default SidebarWithContent;
