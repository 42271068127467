import React from 'react';
import { WithContent } from 'components';
import AuthenticationLinks from './AuthenticationLinks';


const AuthenticationLinksWithContent = (props) => {
  return (
    <WithContent
      id='authentication/links.html'
      Component={AuthenticationLinks}
      {...props} 
    />
  );
}

export default AuthenticationLinksWithContent;
