import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  IconButton,
  Tooltip
} from '@material-ui/core';
import DataListCardContent from '../DataListCardContent';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
  root: {},
  formGroup: {
    marginBottom: theme.spacing(3)
  }
}));

const AddDataListCard = props => {
  const classes = useStyles();
  const [values, setValues] = useState({});

  const handleFieldChange = (event, field, value) => {
    event && event.persist && event.persist();
    setValues(values => ({
      ...values,
      [field]: value
    }));
  };

  const handleClickAdd = () => {
    const { onRowAdd } = props.editable;
    const { onAdd } = props;
    onRowAdd(values);
    setValues({});
    onAdd();
  };
  
  const { columns, onCancel, title } = props;
  return (
    <Card
      className={clsx(classes.root)}
    >
      <CardHeader
        title={title}
      />
      <DataListCardContent
        edit={true}
        columns={columns}
        values={values}
        handleFieldChange={handleFieldChange}
      />
      <CardActions disableSpacing>
        <Tooltip title="Save">
          <IconButton
            onClick={handleClickAdd}
            aria-label="Save"
          >
            <CheckIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel">
          <IconButton
            onClick={onCancel}
            aria-label="Cancel"
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

AddDataListCard.propTypes = {
  className: PropTypes.string
};

export default AddDataListCard;
