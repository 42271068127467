import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid } from '@material-ui/core';
import { withStorage } from 'components';

import { FileCard, FilesDropzone } from './components';

const useStyles = makeStyles(theme => ({
  root: {},
  files: {
    marginTop: theme.spacing(3)
  }
}));

const Files = props => {
  const { files = [], folder, className, actions, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardContent>
          <FilesDropzone actions={actions} folder={folder} />
        </CardContent>
      </Card>
      <Grid
        className={classes.files}
        container
        spacing={3}
      >
        {files.map(file => (
          <Grid
            item
            key={file.id}
            lg={4}
            md={4}
            sm={6}
            xs={12}
          >
            <FileCard file={file} actions={actions} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Files.propTypes = {
  className: PropTypes.string,
  files: PropTypes.array,
  uploadFile: PropTypes.func
};

export default withStorage(Files);
