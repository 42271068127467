import { useContext, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { UserContext } from './userContext';

const useGroups = () => {
  const userContext = useContext(UserContext);
  const [groups, setGroups] = useState(userContext.groups);

  async function getGroups() {
    const groupsReq = await API.get('AdminQueries', '/listGroups', {
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      } 
    });
    
    setGroups(groupsReq.Groups.map(group => {
      return group.GroupName
    }));
  }

  useEffect(() => {
    if (groups.indexOf('Admin') > -1) {
      getGroups();
    }
    
  }, [userContext.groups]);

  return { groups };
}

export default useGroups;
