/* eslint react/no-multi-comp: "off" */
import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

import DataListHeader from './components/DataListHeader';
import DataListCard from './components/DataListCard';
import AddDataListCard from './components/AddDataListCard';
import Table from 'components/Table';
import { MTableToolbar } from 'material-table';
import ViewListIcon from '@material-ui/icons/ViewList';
import TableChartIcon from '@material-ui/icons/TableChart';
import { Markdown } from 'components';
import useContent from 'hooks/useContent';


const DataListCardWrapper = props => {
  const { add, addTitle, columns, detailPanel, editable, grid, id, onCancelAdd, onCompleteAdd, sortedData, localization } = props;
  const gridProps = { xs: 12, sm: 6, md: 6, ...grid };
  
  return (
    <Grid container spacing={3}>
      {add && (
        <Grid item {...gridProps} >
          <AddDataListCard
            localization={localization}
            columns={columns}
            detailPanel={detailPanel}
            editable={editable}
            onAdd={onCompleteAdd}
            onCancel={onCancelAdd}
            title={addTitle}
          />
        </Grid>
      )}
      {sortedData && sortedData.map((item, index) => {
        return (
          <Grid
            item
            {...gridProps}
            key={`grid-${id}-${index}`}
          >
            <DataListCard
              localization={localization}
              columns={columns}
              data={item}
              detailPanel={detailPanel}
              editable={editable}
              id={`${id}-${index}`}
            />
          </Grid>
        );
      })}
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  tableContainer: {
    marginTop: theme.spacing(1.5)
  },
  contents: {
    padding: theme.spacing(2)
  }
}));

const DataList = props => {
  const classes = useStyles();
  const {localization, noDataTitle} = useContent('tables/table.html');
  const { tableViewLabel, listViewLabel } = useContent('labels.html');
  const { id, data, columns, editable, contents, title, addTitle, detailPanel, grid, options = {}, maxRecords, forceList = false, noData, loading } = props;
  const defaultOptions = {
    sorting: data.length > 1,
    listView: true,
    tableView: true,
    defaultView: 'list'
  };
  const _options = Object.assign({}, defaultOptions, options);
  const isEditable = typeof editable !== 'undefined';
  const maxRecordsReached = typeof maxRecords !== 'undefined' && data.length >= maxRecords;
  const canAdd = isEditable && typeof editable.onRowAdd !== 'undefined' && !maxRecordsReached;

  const addInitialValue = (canAdd && data.length === 0 );

  const [add, setAdd] = React.useState(addInitialValue);
  const [sort, setSort] = React.useState(null);
  const [currentView, setCurrentView] = React.useState(_options.defaultView);
  const onlyAllowOneRecord = typeof maxRecords !== 'undefined' && maxRecords === 1;
  const hideAddButton = !canAdd || (onlyAllowOneRecord && add);

  const onClickAdd = () => {
    setAdd(true);
  };

  const onCancelAdd = () => {
    setAdd(false);
  };

  const onCompleteAdd = () => {
    setAdd(false);
  };

  const onSort = (selectedSort) => {
    setSort(selectedSort);
  };

  const onClickChangeView = () => {
    setCurrentView(currentView === 'list' ? 'table' : 'list');
  }

  const sortedData = sort ? data.sort((a, b) => {
    if ((sort.asc && a[sort.field] === null) || (!sort.asc && b[sort.field] === null)) {
      return 0;
    } else {
      return sort.asc ? a[sort.field].localeCompare(b[sort.field]) : b[sort.field].localeCompare(a[sort.field]);
    }
  }) : data;

  const showNoData = !loading && sortedData.length === 0 && noData;

  return (
    <Fragment>
      {showNoData && (
        <Alert severity="info">
          <AlertTitle>{noDataTitle}</AlertTitle>
          {showNoData.message}
        </Alert>
      )}
      {!showNoData && currentView === 'list' && (
        <Fragment>
          <DataListHeader
            localization={localization}
            columns={props.columns}
            contents={contents}
            currentView={currentView}
            forceList={forceList}
            id={id}
            onClickAdd={(canAdd && !hideAddButton) ? onClickAdd : null}
            onClickChangeView={onClickChangeView}
            onSort={_options.sorting ? onSort : null}
            options={_options}
            title={title}
          />
          <DataListCardWrapper
            localization={localization}
            add={add}
            addTitle={addTitle}
            columns={columns}
            detailPanel={detailPanel}
            editable={editable}
            grid={grid}
            id={id}
            onCancelAdd={onCancelAdd}
            onCompleteAdd={onCompleteAdd}
            sortedData={sortedData}
          />
        </Fragment>
      )}
      {!showNoData && currentView === 'table' && (
        <div className={classes.tableContainer}>
          <Table
            localization={localization}
            add={add}
            columns={columns}
            data={sortedData}
            detailPanel={detailPanel}
            editable={editable}
            id={id}
            onCancelAdd={onCancelAdd}
            onCompleteAdd={onCompleteAdd}
            options={{
              search: true,
              sorting: true,
              exportButton: true,
              ..._options
            }}
            components={{
              Toolbar: props => {
                const newProps = {
                  ...props
                };
                if (_options.listView && _options.tableView) {
                  const CurrentViewIcon = currentView === 'list' ? TableChartIcon : ViewListIcon;
                  newProps.actions = [
                    ...props.actions,
                    {
                      icon: CurrentViewIcon,
                      tooltip: currentView === 'list' ? tableViewLabel : listViewLabel,
                      isFreeAction: true,
                      onClick: onClickChangeView
                    }
                  ];
                }
                return (
                  <Fragment>
                    <MTableToolbar {...newProps} />
                    {contents && (
                      <div className={classes.contents}>
                        <Markdown contents={contents} />
                      </div>
                    )}
                  </Fragment>
                )
              },
            }}
            title={title}
          />
        </div>
      )}
    </Fragment>
  );
};

DataList.defaultProps = {
  id: 'data-list'
}

export default DataList;
