import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { RouteWithRedirect } from 'components';

import {
  Main as MainLayout,
  Worksheet
} from 'layouts';

const locale = '/:locale(en|fr)';
const milestone = '/:milestone(milestone1|milestone2|milestone3|milestone4|milestone5)'
const basePath = locale + milestone;

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/en/introduction" />
  },
  {
    path: locale + '/introduction',
    component: MainLayout,
    routes: [
      {
        path: locale + '/introduction',
        exact: true,
        component: lazy(() => import('views/Introduction'))
      }
    ]
  },
  {
    path: locale + '/settings',
    component: Worksheet,
    showSidebar: false,
    routes: [
      {
        path: locale + '/settings',
        exact: true,
        component: lazy(() => import('views/Settings'))
      }
    ]
  },
  {
    path: locale + '/:milestone(annual-report)',
    component: Worksheet,
    showSidebar: false,
    exact: true,
    routes: [
      {
        path: locale + '/:milestone(annual-report)',
        exact: true,
        component: lazy(() => import('views/AnnualReport/Years'))
      }
    ]
  },
  {
    path: locale + '/:milestone(annual-report)/year/:year/question/current-population',
    exact: true,
    component: Worksheet,
    routes: [
      {
        path: locale + '/:milestone(annual-report)/year/:year/question/current-population',
        exact: true,
        component: lazy(() => import('views/AnnualReport/CurrentPopulation'))
      }
    ]
  },
  {
    path: locale + '/:milestone(annual-report)/year/:year/question/hazards',
    exact: true,
    component: Worksheet,
    routes: [
      {
        path: locale + '/:milestone(annual-report)/year/:year/question/hazards',
        exact: true,
        component: lazy(() => import('views/AnnualReport/Hazards'))
      }
    ]
  },
  {
    path: locale + '/:milestone(annual-report)/year/:year/question/ability-to-adapt',
    exact: true,
    component: Worksheet,
    routes: [
      {
        path: locale + '/:milestone(annual-report)/year/:year/question/ability-to-adapt',
        exact: true,
        component: lazy(() => import('views/AnnualReport/AbilityToAdapt'))
      }
    ]
  },
  {
    path: locale + '/:milestone(annual-report)/year/:year/question/adaptation-plans',
    exact: true,
    component: Worksheet,
    routes: [
      {
        path: locale + '/:milestone(annual-report)/year/:year/question/adaptation-plans',
        exact: true,
        component: lazy(() => import('views/AnnualReport/AdaptationPlans'))
      }
    ]
  },
  {
    path: locale + '/:milestone(annual-report)/year/:year/question/actions',
    exact: true,
    component: Worksheet,
    routes: [
      {
        path: locale + '/:milestone(annual-report)/year/:year/question/actions',
        exact: true,
        component: lazy(() => import('views/AnnualReport/Actions'))
      }
    ]
  },
  {
    path: locale + '/:milestone(annual-report)/year/:year/submit',
    component: Worksheet,
    exact: true,
    routes: [
      {
        path: locale + '/:milestone(annual-report)/year/:year/submit',
        exact: true,
        component: lazy(() => import('views/AnnualReport/Submit'))
      }
    ]
  },
  {
    path: locale + '/:milestone(annual-report)/year/:year/badges',
    component: Worksheet,
    exact: true,
    routes: [
      {
        path: locale + '/:milestone(annual-report)/year/:year/badges',
        exact: true,
        component: lazy(() => import('views/AnnualReport/Badges'))
      }
    ]
  },
  {
    path: basePath,
    component: Worksheet,
    routes: [
      
      // MILESTONES
      {
        path: locale + '/milestone1/introduction',
        exact: true,
        component: lazy(() => import('views/Milestone1'))
      },
      {
        path: locale + '/milestone1',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone1/introduction" />
      },
      {
        path: locale + '/milestone2/introduction',
        exact: true,
        component: lazy(() => import('views/Milestone2'))
      },
      {
        path: locale + '/milestone2',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone2/introduction" />
      },
      {
        path: locale + '/milestone3/introduction',
        exact: true,
        component: lazy(() => import('views/Milestone3'))
      },
      {
        path: locale + '/milestone3',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone3/introduction" />
      },
      {
        path: locale + '/milestone4/introduction',
        exact: true,
        component: lazy(() => import('views/Milestone4'))
      },
      {
        path: locale + '/milestone4',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone4/introduction" />
      },
      {
        path: locale + '/milestone5/introduction',
        exact: true,
        component: lazy(() => import('views/Milestone5'))
      },
      {
        path: locale + '/milestone5',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone5/introduction" />
      },
      {
        path: basePath + '/stakeholder-identification',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone1/stakeholder-identification/overview" />
      },
      {
        path: basePath + '/stakeholder-identification/:tab?',
        exact: true,
        component: lazy(() => import('views/StakeholderIdentification'))
      },
      {
        path: basePath + '/tasking-your-adaptation-team',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone1/tasking-your-adaptation-team/overview" />
      },
      {
        path: basePath + '/tasking-your-adaptation-team/:tab?',
        exact: true,
        component: lazy(() => import('views/TaskingAnAdaptationTeam'))
      },
      {
        path: basePath + '/current-weather-hazards',
        exact: true,
        component: lazy(() => import('views/CurrentWeatherHazards'))
      },
      {
        path: basePath + '/current-plans-policies-and-programs',
        exact: true,
        component: lazy(() => import('views/CurrentPlansPoliciesAndPrograms'))
      },
      {
        path: basePath + '/communicating-with-stakeholders',
        exact: true,
        component: lazy(() => import('views/CommunicatingWithStakeholders'))
      },
      {
        path: basePath + '/securing-support',
        exact: true,
        component: lazy(() => import('views/SecuringSupport'))
      },
      {
        path: basePath + '/recording-climate-change-projections',
        exact: true,
        component: lazy(() => import('views/RecordingClimateChangeProjections'))
      },
      {
        path: basePath + '/developing-impact-statements',
        exact: true,
        component: lazy(() => import('views/DevelopingImpactStatements'))
      },
      {
        path: basePath + '/vulnerability-assessment',
        exact: true,
        component: lazy(() => import('views/VulnerabilityAssessment'))
      },
      {
        path: basePath + '/sensitivity',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone2/sensitivity/overview" />
      },
      {
        path: basePath + '/sensitivity/:tab?',
        exact: true,
        component: lazy(() => import('views/Sensitivity'))
      },
      {
        path: basePath + '/adaptive-capacity',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone2/adaptive-capacity/overview" />
      },
      {
        path: basePath + '/adaptive-capacity/:tab?',
        exact: true,
        component: lazy(() => import('views/AdaptiveCapacity'))
      },
      {
        path: basePath + '/vulnerability-assessment-results',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone2/vulnerability-assessment-results/overview" />
      },
      {
        path: basePath + '/vulnerability-assessment-results/:tab?',
        exact: true,
        component: lazy(() => import('views/VulnerabilityResults'))
      },
      {
        path: basePath + '/risk-assessment',
        exact: true,
        component: lazy(() => import('views/RiskAssessment'))
      },
      {
        path: basePath + '/likelihood',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone2/likelihood/overview" />
      },
      {
        path: basePath + '/likelihood/:tab?',
        exact: true,
        component: lazy(() => import('views/Likelihood'))
      },
      {
        path: basePath + '/consequences',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone2/consequences/overview" />
      },
      {
        path: basePath + '/consequences/:tab?',
        exact: true,
        component: lazy(() => import('views/Consequences'))
      },
      {
        path: basePath + '/risk-assessment-results',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone2/risk-assessment-results/overview" />
      },
      {
        path: basePath + '/risk-assessment-results/:tab?',
        exact: true,
        component: lazy(() => import('views/RiskAssessmentResults'))
      },
      {
        path: basePath + '/adaptation-vision',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone3/adaptation-vision/overview" />
      },
      {
        path: basePath + '/adaptation-vision/:tab?',
        exact: true,
        component: lazy(() => import('views/AdaptationVision'))
      },
      {
        path: basePath + '/develop-themes',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone3/develop-themes/overview" />
      },
      {
        path: basePath + '/develop-themes/:tab?',
        exact: true,
        component: lazy(() => import('views/DevelopThemes'))
      },
      {
        path: basePath + '/objectives-and-targets',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone3/objectives-and-targets/overview" />
      },
      {
        path: basePath + '/objectives-and-targets/:tab?',
        exact: true,
        component: lazy(() => import('views/ObjectivesAndTargets'))
      },
      {
        path: basePath + '/identify-actions',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone3/identify-actions/overview" />
      },
      {
        path: basePath + '/identify-actions/:tab?',
        exact: true,
        component: lazy(() => import('views/IdentifyActions'))
      },
      {
        path: basePath + '/assess-actions',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone3/assess-actions/overview" />
      },
      {
        path: basePath + '/assess-actions/:tab?',
        exact: true,
        component: lazy(() => import('views/AssessActions'))
      },
      {
        path: basePath + '/identify-indicators',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone3/identify-indicators/overview" />
      },
      {
        path: basePath + '/identify-indicators/:tab?',
        exact: true,
        component: lazy(() => import('views/IdentifyIndicators'))
      },
      {
        path: basePath + '/establish-a-baseline',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone3/establish-a-baseline/overview" />
      },
      {
        path: basePath + '/establish-a-baseline/:tab?',
        exact: true,
        component: lazy(() => import('views/EstablishBaseline'))
      },
      {
        path: basePath + '/identify-implementation-considerations',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone3/identify-implementation-considerations/overview" />
      },
      {
        path: basePath + '/identify-implementation-considerations/:tab?',
        exact: true,
        component: lazy(() => import('views/IdentifyImplementationConsiderations'))
      },
      {
        path: basePath + '/cross-cutting-actions',
        exact: true,
        component: lazy(() => import('views/CrossCuttingActions'))
      },
      {
        path: basePath + '/draft-action-plan',
        exact: true,
        component: lazy(() => import('views/DraftActionPlan'))
      },
      {
        path: basePath + '/implementation-self-assessment/:version',
        exact: true,
        component: lazy(() => import('views/ImplementationSurvey'))
      },
      {
        path: basePath + '/monitoring-the-plan',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone5/monitoring-the-plan/overview" />
      },
      {
        path: basePath + '/monitoring-the-plan/:tab?',
        exact: true,
        component: lazy(() => import('views/MonitoringThePlan'))
      },
      {
        path: basePath + '/updating-the-plan',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone5/updating-the-plan/overview" />
      },
      {
        path: basePath + '/updating-the-plan/:tab?',
        exact: true,
        component: lazy(() => import('views/UpdatingThePlan'))
      },
      {
        path: basePath + '/communicating-accomplishments',
        exact: true,
        component: () => <RouteWithRedirect to="/milestone5/communicating-accomplishments/overview" />
      },
      {
        path: basePath + '/communicating-accomplishments/:tab?',
        exact: true,
        component: lazy(() => import('views/CommunicatingAccomplishments'))
      }
    ]
  }
];

export default routes;
