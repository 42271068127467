/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:7851ea98-1a7c-4493-8351-0fb1e3a1cd22",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_lhD44KwBi",
    "aws_user_pools_web_client_id": "4pr2p8bmes8fvpn0lgtepm9orc",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "barc-tool-20190901144956-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1xfq42w8rqdz1.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://vhyx4uaeirbbvmj7j6nzsk3z4u.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://vymtbpa0xk.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "barc-tool12a81b1ca6cb4180a536acfb125fd8a7-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
