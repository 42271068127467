import React from 'react';
import { WithContent } from 'components';
import BottomPageNavigation from './BottomPageNavigation';


const BottomPageNavigationWithContent = (props) => {
  return (
    <WithContent
      id='bottom-navigation.html'
      Component={BottomPageNavigation}
      {...props} 
    />
  );
}

export default BottomPageNavigationWithContent;
