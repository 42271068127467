import React, { Fragment } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import useContent from 'hooks/useContent';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    marginTop: '12px'
  }
}));

const Sort = (props) => {
  const classes = useStyles();
  const { sortByLabel } = useContent('labels.html');
  const { id, onSort, columns } = props;
  const [sortBy, setSortBy] = React.useState(props.sortBy);
  const [sortAsc, setSortAsc] = React.useState(true);
  
  const sortableColumns = columns.filter(column => {
    let sortable = true;
    if (typeof column.sorting !== 'undefined') {
      sortable = column.sorting;
    }
    return sortable;
  });

  const onChange = (event) => {
    const value = event.target.value
    setSortBy(value);
    onSort({ field: value, asc: sortAsc });
  }

  const onClickSortAsc = () => {
    const newSortAsc = !sortAsc;
    setSortAsc(newSortAsc);
    onSort({ field: sortBy, asc: newSortAsc });
  }

  return(
    <Fragment>
      <FormControl className={classes.formControl}>
        <InputLabel id="sort-by">{sortByLabel}</InputLabel>
        <Select
          labelid="sort-by"
          value={sortBy}
          onChange={onChange}
        >
          {sortableColumns && sortableColumns.map((column, index) => {
            return (
              <MenuItem key={`sort-${id}-${index}`} value={column.field}>{column.title}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {sortBy && (
        <IconButton
          className={classes.direction}
          onClick={onClickSortAsc}
        >
          <SwapVertIcon />
        </IconButton>
      )}
    </Fragment>
  );
};

Sort.defaultProps = {
  id: 'sort',
  sortBy: ''
};

export default Sort;
