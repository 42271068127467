/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const submitAnnualReport = /* GraphQL */ `
  mutation SubmitAnnualReport($report: AWSJSON, $year: String) {
    submitAnnualReport(report: $report, year: $year)
  }
`;
export const submitBadges = /* GraphQL */ `
  mutation SubmitBadges($badges: AWSJSON) {
    submitBadges(badges: $badges)
  }
`;
export const createStakeholder = /* GraphQL */ `
  mutation CreateStakeholder(
    $input: CreateStakeholderInput!
    $condition: ModelStakeholderConditionInput
  ) {
    createStakeholder(input: $input, condition: $condition) {
      id
      name
      title
      organization
      email
      phone
      rationale
      onTeam
      ifNotOnTeam
      comment
      accepted
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateStakeholder = /* GraphQL */ `
  mutation UpdateStakeholder(
    $input: UpdateStakeholderInput!
    $condition: ModelStakeholderConditionInput
  ) {
    updateStakeholder(input: $input, condition: $condition) {
      id
      name
      title
      organization
      email
      phone
      rationale
      onTeam
      ifNotOnTeam
      comment
      accepted
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteStakeholder = /* GraphQL */ `
  mutation DeleteStakeholder(
    $input: DeleteStakeholderInput!
    $condition: ModelStakeholderConditionInput
  ) {
    deleteStakeholder(input: $input, condition: $condition) {
      id
      name
      title
      organization
      email
      phone
      rationale
      onTeam
      ifNotOnTeam
      comment
      accepted
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      mandate
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      mandate
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      mandate
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createTeamLeader = /* GraphQL */ `
  mutation CreateTeamLeader(
    $input: CreateTeamLeaderInput!
    $condition: ModelTeamLeaderConditionInput
  ) {
    createTeamLeader(input: $input, condition: $condition) {
      id
      name
      title
      organization
      contact
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateTeamLeader = /* GraphQL */ `
  mutation UpdateTeamLeader(
    $input: UpdateTeamLeaderInput!
    $condition: ModelTeamLeaderConditionInput
  ) {
    updateTeamLeader(input: $input, condition: $condition) {
      id
      name
      title
      organization
      contact
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteTeamLeader = /* GraphQL */ `
  mutation DeleteTeamLeader(
    $input: DeleteTeamLeaderInput!
    $condition: ModelTeamLeaderConditionInput
  ) {
    deleteTeamLeader(input: $input, condition: $condition) {
      id
      name
      title
      organization
      contact
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createTeamChampion = /* GraphQL */ `
  mutation CreateTeamChampion(
    $input: CreateTeamChampionInput!
    $condition: ModelTeamChampionConditionInput
  ) {
    createTeamChampion(input: $input, condition: $condition) {
      id
      name
      title
      organization
      contact
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateTeamChampion = /* GraphQL */ `
  mutation UpdateTeamChampion(
    $input: UpdateTeamChampionInput!
    $condition: ModelTeamChampionConditionInput
  ) {
    updateTeamChampion(input: $input, condition: $condition) {
      id
      name
      title
      organization
      contact
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteTeamChampion = /* GraphQL */ `
  mutation DeleteTeamChampion(
    $input: DeleteTeamChampionInput!
    $condition: ModelTeamChampionConditionInput
  ) {
    deleteTeamChampion(input: $input, condition: $condition) {
      id
      name
      title
      organization
      contact
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createWeatherHazard = /* GraphQL */ `
  mutation CreateWeatherHazard(
    $input: CreateWeatherHazardInput!
    $condition: ModelWeatherHazardConditionInput
  ) {
    createWeatherHazard(input: $input, condition: $condition) {
      id
      description
      date
      effects
      source
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateWeatherHazard = /* GraphQL */ `
  mutation UpdateWeatherHazard(
    $input: UpdateWeatherHazardInput!
    $condition: ModelWeatherHazardConditionInput
  ) {
    updateWeatherHazard(input: $input, condition: $condition) {
      id
      description
      date
      effects
      source
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteWeatherHazard = /* GraphQL */ `
  mutation DeleteWeatherHazard(
    $input: DeleteWeatherHazardInput!
    $condition: ModelWeatherHazardConditionInput
  ) {
    deleteWeatherHazard(input: $input, condition: $condition) {
      id
      description
      date
      effects
      source
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      name
      type
      year
      relevance
      link
      notes
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      name
      type
      year
      relevance
      link
      notes
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      name
      type
      year
      relevance
      link
      notes
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createCommunication = /* GraphQL */ `
  mutation CreateCommunication(
    $input: CreateCommunicationInput!
    $condition: ModelCommunicationConditionInput
  ) {
    createCommunication(input: $input, condition: $condition) {
      id
      stakeholder
      organization
      contact
      medium
      timing
      notes
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateCommunication = /* GraphQL */ `
  mutation UpdateCommunication(
    $input: UpdateCommunicationInput!
    $condition: ModelCommunicationConditionInput
  ) {
    updateCommunication(input: $input, condition: $condition) {
      id
      stakeholder
      organization
      contact
      medium
      timing
      notes
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteCommunication = /* GraphQL */ `
  mutation DeleteCommunication(
    $input: DeleteCommunicationInput!
    $condition: ModelCommunicationConditionInput
  ) {
    deleteCommunication(input: $input, condition: $condition) {
      id
      stakeholder
      organization
      contact
      medium
      timing
      notes
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createClimaticChange = /* GraphQL */ `
  mutation CreateClimaticChange(
    $input: CreateClimaticChangeInput!
    $condition: ModelClimaticChangeConditionInput
  ) {
    createClimaticChange(input: $input, condition: $condition) {
      id
      platform
      model
      emissionsScenario
      baselineYear
      timeframe
      seasonalVariability
      missingData
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateClimaticChange = /* GraphQL */ `
  mutation UpdateClimaticChange(
    $input: UpdateClimaticChangeInput!
    $condition: ModelClimaticChangeConditionInput
  ) {
    updateClimaticChange(input: $input, condition: $condition) {
      id
      platform
      model
      emissionsScenario
      baselineYear
      timeframe
      seasonalVariability
      missingData
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteClimaticChange = /* GraphQL */ `
  mutation DeleteClimaticChange(
    $input: DeleteClimaticChangeInput!
    $condition: ModelClimaticChangeConditionInput
  ) {
    deleteClimaticChange(input: $input, condition: $condition) {
      id
      platform
      model
      emissionsScenario
      baselineYear
      timeframe
      seasonalVariability
      missingData
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createAdaptationAction = /* GraphQL */ `
  mutation CreateAdaptationAction(
    $input: CreateAdaptationActionInput!
    $condition: ModelAdaptationActionConditionInput
  ) {
    createAdaptationAction(input: $input, condition: $condition) {
      id
      climateImpact {
        id
        climateConditionId
        outcome
        consequence
        statement
        affectedAreas
        sensitivity
        sensitivityJustification
        adaptiveCapacity
        adaptiveCapacityJustification
        carryForward
        type
        likelihood
        likelihoodJustification
        publicHealthConsequence
        displacementConsequence
        lossOfLivelihoodConsequence
        culturalAspectsConsequence
        socialConsequenceRiskScore
        socialConsequenceRiskLevel
        propertyDamageConsequence
        localEconomyConsequence
        communityLivabilityConsequence
        publicAdministrationConsequence
        economicConsequenceRiskScore
        economicConsequenceRiskLevel
        airConsequence
        waterConsequence
        soilAndVegetationConsequence
        ecosytemFunctionConsequence
        naturalConsequenceRiskScore
        naturalConsequenceRiskLevel
        totalRisk
        totalRiskScore
        totalRiskLevel
        vulnerabilityRanking
        theme {
          id
          name
          updatedAt
          createdAt
          owner
          region
        }
        adaptationActions {
          nextToken
        }
        objective
        requiredInformation
        target
        includeInPlan
        updatedAt
        createdAt
        owner
        region
      }
      indicators {
        items {
          id
          name
          willInformBaseline
          requiredData
          dataExists
          responsibleParty
          nextSteps
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      adaptationAction
      leadDepartment
      supportingDepartment
      justification
      includeInPlan
      scope
      supportingActions {
        items {
          id
          action
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      timeline
      costs
      additionalResources
      funding
      notes
      status
      implementedBy
      actualCosts
      monitoringNotes
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateAdaptationAction = /* GraphQL */ `
  mutation UpdateAdaptationAction(
    $input: UpdateAdaptationActionInput!
    $condition: ModelAdaptationActionConditionInput
  ) {
    updateAdaptationAction(input: $input, condition: $condition) {
      id
      climateImpact {
        id
        climateConditionId
        outcome
        consequence
        statement
        affectedAreas
        sensitivity
        sensitivityJustification
        adaptiveCapacity
        adaptiveCapacityJustification
        carryForward
        type
        likelihood
        likelihoodJustification
        publicHealthConsequence
        displacementConsequence
        lossOfLivelihoodConsequence
        culturalAspectsConsequence
        socialConsequenceRiskScore
        socialConsequenceRiskLevel
        propertyDamageConsequence
        localEconomyConsequence
        communityLivabilityConsequence
        publicAdministrationConsequence
        economicConsequenceRiskScore
        economicConsequenceRiskLevel
        airConsequence
        waterConsequence
        soilAndVegetationConsequence
        ecosytemFunctionConsequence
        naturalConsequenceRiskScore
        naturalConsequenceRiskLevel
        totalRisk
        totalRiskScore
        totalRiskLevel
        vulnerabilityRanking
        theme {
          id
          name
          updatedAt
          createdAt
          owner
          region
        }
        adaptationActions {
          nextToken
        }
        objective
        requiredInformation
        target
        includeInPlan
        updatedAt
        createdAt
        owner
        region
      }
      indicators {
        items {
          id
          name
          willInformBaseline
          requiredData
          dataExists
          responsibleParty
          nextSteps
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      adaptationAction
      leadDepartment
      supportingDepartment
      justification
      includeInPlan
      scope
      supportingActions {
        items {
          id
          action
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      timeline
      costs
      additionalResources
      funding
      notes
      status
      implementedBy
      actualCosts
      monitoringNotes
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteAdaptationAction = /* GraphQL */ `
  mutation DeleteAdaptationAction(
    $input: DeleteAdaptationActionInput!
    $condition: ModelAdaptationActionConditionInput
  ) {
    deleteAdaptationAction(input: $input, condition: $condition) {
      id
      climateImpact {
        id
        climateConditionId
        outcome
        consequence
        statement
        affectedAreas
        sensitivity
        sensitivityJustification
        adaptiveCapacity
        adaptiveCapacityJustification
        carryForward
        type
        likelihood
        likelihoodJustification
        publicHealthConsequence
        displacementConsequence
        lossOfLivelihoodConsequence
        culturalAspectsConsequence
        socialConsequenceRiskScore
        socialConsequenceRiskLevel
        propertyDamageConsequence
        localEconomyConsequence
        communityLivabilityConsequence
        publicAdministrationConsequence
        economicConsequenceRiskScore
        economicConsequenceRiskLevel
        airConsequence
        waterConsequence
        soilAndVegetationConsequence
        ecosytemFunctionConsequence
        naturalConsequenceRiskScore
        naturalConsequenceRiskLevel
        totalRisk
        totalRiskScore
        totalRiskLevel
        vulnerabilityRanking
        theme {
          id
          name
          updatedAt
          createdAt
          owner
          region
        }
        adaptationActions {
          nextToken
        }
        objective
        requiredInformation
        target
        includeInPlan
        updatedAt
        createdAt
        owner
        region
      }
      indicators {
        items {
          id
          name
          willInformBaseline
          requiredData
          dataExists
          responsibleParty
          nextSteps
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      adaptationAction
      leadDepartment
      supportingDepartment
      justification
      includeInPlan
      scope
      supportingActions {
        items {
          id
          action
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      timeline
      costs
      additionalResources
      funding
      notes
      status
      implementedBy
      actualCosts
      monitoringNotes
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createClimateImpact = /* GraphQL */ `
  mutation CreateClimateImpact(
    $input: CreateClimateImpactInput!
    $condition: ModelClimateImpactConditionInput
  ) {
    createClimateImpact(input: $input, condition: $condition) {
      id
      climateConditionId
      outcome
      consequence
      statement
      affectedAreas
      sensitivity
      sensitivityJustification
      adaptiveCapacity
      adaptiveCapacityJustification
      carryForward
      type
      likelihood
      likelihoodJustification
      publicHealthConsequence
      displacementConsequence
      lossOfLivelihoodConsequence
      culturalAspectsConsequence
      socialConsequenceRiskScore
      socialConsequenceRiskLevel
      propertyDamageConsequence
      localEconomyConsequence
      communityLivabilityConsequence
      publicAdministrationConsequence
      economicConsequenceRiskScore
      economicConsequenceRiskLevel
      airConsequence
      waterConsequence
      soilAndVegetationConsequence
      ecosytemFunctionConsequence
      naturalConsequenceRiskScore
      naturalConsequenceRiskLevel
      totalRisk
      totalRiskScore
      totalRiskLevel
      vulnerabilityRanking
      theme {
        id
        name
        climateImpacts {
          nextToken
        }
        updatedAt
        createdAt
        owner
        region
      }
      adaptationActions {
        items {
          id
          adaptationAction
          leadDepartment
          supportingDepartment
          justification
          includeInPlan
          scope
          timeline
          costs
          additionalResources
          funding
          notes
          status
          implementedBy
          actualCosts
          monitoringNotes
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      objective
      requiredInformation
      target
      includeInPlan
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateClimateImpact = /* GraphQL */ `
  mutation UpdateClimateImpact(
    $input: UpdateClimateImpactInput!
    $condition: ModelClimateImpactConditionInput
  ) {
    updateClimateImpact(input: $input, condition: $condition) {
      id
      climateConditionId
      outcome
      consequence
      statement
      affectedAreas
      sensitivity
      sensitivityJustification
      adaptiveCapacity
      adaptiveCapacityJustification
      carryForward
      type
      likelihood
      likelihoodJustification
      publicHealthConsequence
      displacementConsequence
      lossOfLivelihoodConsequence
      culturalAspectsConsequence
      socialConsequenceRiskScore
      socialConsequenceRiskLevel
      propertyDamageConsequence
      localEconomyConsequence
      communityLivabilityConsequence
      publicAdministrationConsequence
      economicConsequenceRiskScore
      economicConsequenceRiskLevel
      airConsequence
      waterConsequence
      soilAndVegetationConsequence
      ecosytemFunctionConsequence
      naturalConsequenceRiskScore
      naturalConsequenceRiskLevel
      totalRisk
      totalRiskScore
      totalRiskLevel
      vulnerabilityRanking
      theme {
        id
        name
        climateImpacts {
          nextToken
        }
        updatedAt
        createdAt
        owner
        region
      }
      adaptationActions {
        items {
          id
          adaptationAction
          leadDepartment
          supportingDepartment
          justification
          includeInPlan
          scope
          timeline
          costs
          additionalResources
          funding
          notes
          status
          implementedBy
          actualCosts
          monitoringNotes
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      objective
      requiredInformation
      target
      includeInPlan
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteClimateImpact = /* GraphQL */ `
  mutation DeleteClimateImpact(
    $input: DeleteClimateImpactInput!
    $condition: ModelClimateImpactConditionInput
  ) {
    deleteClimateImpact(input: $input, condition: $condition) {
      id
      climateConditionId
      outcome
      consequence
      statement
      affectedAreas
      sensitivity
      sensitivityJustification
      adaptiveCapacity
      adaptiveCapacityJustification
      carryForward
      type
      likelihood
      likelihoodJustification
      publicHealthConsequence
      displacementConsequence
      lossOfLivelihoodConsequence
      culturalAspectsConsequence
      socialConsequenceRiskScore
      socialConsequenceRiskLevel
      propertyDamageConsequence
      localEconomyConsequence
      communityLivabilityConsequence
      publicAdministrationConsequence
      economicConsequenceRiskScore
      economicConsequenceRiskLevel
      airConsequence
      waterConsequence
      soilAndVegetationConsequence
      ecosytemFunctionConsequence
      naturalConsequenceRiskScore
      naturalConsequenceRiskLevel
      totalRisk
      totalRiskScore
      totalRiskLevel
      vulnerabilityRanking
      theme {
        id
        name
        climateImpacts {
          nextToken
        }
        updatedAt
        createdAt
        owner
        region
      }
      adaptationActions {
        items {
          id
          adaptationAction
          leadDepartment
          supportingDepartment
          justification
          includeInPlan
          scope
          timeline
          costs
          additionalResources
          funding
          notes
          status
          implementedBy
          actualCosts
          monitoringNotes
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      objective
      requiredInformation
      target
      includeInPlan
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createVision = /* GraphQL */ `
  mutation CreateVision(
    $input: CreateVisionInput!
    $condition: ModelVisionConditionInput
  ) {
    createVision(input: $input, condition: $condition) {
      id
      vision
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateVision = /* GraphQL */ `
  mutation UpdateVision(
    $input: UpdateVisionInput!
    $condition: ModelVisionConditionInput
  ) {
    updateVision(input: $input, condition: $condition) {
      id
      vision
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteVision = /* GraphQL */ `
  mutation DeleteVision(
    $input: DeleteVisionInput!
    $condition: ModelVisionConditionInput
  ) {
    deleteVision(input: $input, condition: $condition) {
      id
      vision
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createTheme = /* GraphQL */ `
  mutation CreateTheme(
    $input: CreateThemeInput!
    $condition: ModelThemeConditionInput
  ) {
    createTheme(input: $input, condition: $condition) {
      id
      name
      climateImpacts {
        items {
          id
          climateConditionId
          outcome
          consequence
          statement
          affectedAreas
          sensitivity
          sensitivityJustification
          adaptiveCapacity
          adaptiveCapacityJustification
          carryForward
          type
          likelihood
          likelihoodJustification
          publicHealthConsequence
          displacementConsequence
          lossOfLivelihoodConsequence
          culturalAspectsConsequence
          socialConsequenceRiskScore
          socialConsequenceRiskLevel
          propertyDamageConsequence
          localEconomyConsequence
          communityLivabilityConsequence
          publicAdministrationConsequence
          economicConsequenceRiskScore
          economicConsequenceRiskLevel
          airConsequence
          waterConsequence
          soilAndVegetationConsequence
          ecosytemFunctionConsequence
          naturalConsequenceRiskScore
          naturalConsequenceRiskLevel
          totalRisk
          totalRiskScore
          totalRiskLevel
          vulnerabilityRanking
          objective
          requiredInformation
          target
          includeInPlan
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateTheme = /* GraphQL */ `
  mutation UpdateTheme(
    $input: UpdateThemeInput!
    $condition: ModelThemeConditionInput
  ) {
    updateTheme(input: $input, condition: $condition) {
      id
      name
      climateImpacts {
        items {
          id
          climateConditionId
          outcome
          consequence
          statement
          affectedAreas
          sensitivity
          sensitivityJustification
          adaptiveCapacity
          adaptiveCapacityJustification
          carryForward
          type
          likelihood
          likelihoodJustification
          publicHealthConsequence
          displacementConsequence
          lossOfLivelihoodConsequence
          culturalAspectsConsequence
          socialConsequenceRiskScore
          socialConsequenceRiskLevel
          propertyDamageConsequence
          localEconomyConsequence
          communityLivabilityConsequence
          publicAdministrationConsequence
          economicConsequenceRiskScore
          economicConsequenceRiskLevel
          airConsequence
          waterConsequence
          soilAndVegetationConsequence
          ecosytemFunctionConsequence
          naturalConsequenceRiskScore
          naturalConsequenceRiskLevel
          totalRisk
          totalRiskScore
          totalRiskLevel
          vulnerabilityRanking
          objective
          requiredInformation
          target
          includeInPlan
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteTheme = /* GraphQL */ `
  mutation DeleteTheme(
    $input: DeleteThemeInput!
    $condition: ModelThemeConditionInput
  ) {
    deleteTheme(input: $input, condition: $condition) {
      id
      name
      climateImpacts {
        items {
          id
          climateConditionId
          outcome
          consequence
          statement
          affectedAreas
          sensitivity
          sensitivityJustification
          adaptiveCapacity
          adaptiveCapacityJustification
          carryForward
          type
          likelihood
          likelihoodJustification
          publicHealthConsequence
          displacementConsequence
          lossOfLivelihoodConsequence
          culturalAspectsConsequence
          socialConsequenceRiskScore
          socialConsequenceRiskLevel
          propertyDamageConsequence
          localEconomyConsequence
          communityLivabilityConsequence
          publicAdministrationConsequence
          economicConsequenceRiskScore
          economicConsequenceRiskLevel
          airConsequence
          waterConsequence
          soilAndVegetationConsequence
          ecosytemFunctionConsequence
          naturalConsequenceRiskScore
          naturalConsequenceRiskLevel
          totalRisk
          totalRiskScore
          totalRiskLevel
          vulnerabilityRanking
          objective
          requiredInformation
          target
          includeInPlan
          updatedAt
          createdAt
          owner
          region
        }
        nextToken
      }
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createSupportingAction = /* GraphQL */ `
  mutation CreateSupportingAction(
    $input: CreateSupportingActionInput!
    $condition: ModelSupportingActionConditionInput
  ) {
    createSupportingAction(input: $input, condition: $condition) {
      id
      adaptationAction {
        id
        climateImpact {
          id
          climateConditionId
          outcome
          consequence
          statement
          affectedAreas
          sensitivity
          sensitivityJustification
          adaptiveCapacity
          adaptiveCapacityJustification
          carryForward
          type
          likelihood
          likelihoodJustification
          publicHealthConsequence
          displacementConsequence
          lossOfLivelihoodConsequence
          culturalAspectsConsequence
          socialConsequenceRiskScore
          socialConsequenceRiskLevel
          propertyDamageConsequence
          localEconomyConsequence
          communityLivabilityConsequence
          publicAdministrationConsequence
          economicConsequenceRiskScore
          economicConsequenceRiskLevel
          airConsequence
          waterConsequence
          soilAndVegetationConsequence
          ecosytemFunctionConsequence
          naturalConsequenceRiskScore
          naturalConsequenceRiskLevel
          totalRisk
          totalRiskScore
          totalRiskLevel
          vulnerabilityRanking
          objective
          requiredInformation
          target
          includeInPlan
          updatedAt
          createdAt
          owner
          region
        }
        indicators {
          nextToken
        }
        adaptationAction
        leadDepartment
        supportingDepartment
        justification
        includeInPlan
        scope
        supportingActions {
          nextToken
        }
        timeline
        costs
        additionalResources
        funding
        notes
        status
        implementedBy
        actualCosts
        monitoringNotes
        updatedAt
        createdAt
        owner
        region
      }
      action
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateSupportingAction = /* GraphQL */ `
  mutation UpdateSupportingAction(
    $input: UpdateSupportingActionInput!
    $condition: ModelSupportingActionConditionInput
  ) {
    updateSupportingAction(input: $input, condition: $condition) {
      id
      adaptationAction {
        id
        climateImpact {
          id
          climateConditionId
          outcome
          consequence
          statement
          affectedAreas
          sensitivity
          sensitivityJustification
          adaptiveCapacity
          adaptiveCapacityJustification
          carryForward
          type
          likelihood
          likelihoodJustification
          publicHealthConsequence
          displacementConsequence
          lossOfLivelihoodConsequence
          culturalAspectsConsequence
          socialConsequenceRiskScore
          socialConsequenceRiskLevel
          propertyDamageConsequence
          localEconomyConsequence
          communityLivabilityConsequence
          publicAdministrationConsequence
          economicConsequenceRiskScore
          economicConsequenceRiskLevel
          airConsequence
          waterConsequence
          soilAndVegetationConsequence
          ecosytemFunctionConsequence
          naturalConsequenceRiskScore
          naturalConsequenceRiskLevel
          totalRisk
          totalRiskScore
          totalRiskLevel
          vulnerabilityRanking
          objective
          requiredInformation
          target
          includeInPlan
          updatedAt
          createdAt
          owner
          region
        }
        indicators {
          nextToken
        }
        adaptationAction
        leadDepartment
        supportingDepartment
        justification
        includeInPlan
        scope
        supportingActions {
          nextToken
        }
        timeline
        costs
        additionalResources
        funding
        notes
        status
        implementedBy
        actualCosts
        monitoringNotes
        updatedAt
        createdAt
        owner
        region
      }
      action
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteSupportingAction = /* GraphQL */ `
  mutation DeleteSupportingAction(
    $input: DeleteSupportingActionInput!
    $condition: ModelSupportingActionConditionInput
  ) {
    deleteSupportingAction(input: $input, condition: $condition) {
      id
      adaptationAction {
        id
        climateImpact {
          id
          climateConditionId
          outcome
          consequence
          statement
          affectedAreas
          sensitivity
          sensitivityJustification
          adaptiveCapacity
          adaptiveCapacityJustification
          carryForward
          type
          likelihood
          likelihoodJustification
          publicHealthConsequence
          displacementConsequence
          lossOfLivelihoodConsequence
          culturalAspectsConsequence
          socialConsequenceRiskScore
          socialConsequenceRiskLevel
          propertyDamageConsequence
          localEconomyConsequence
          communityLivabilityConsequence
          publicAdministrationConsequence
          economicConsequenceRiskScore
          economicConsequenceRiskLevel
          airConsequence
          waterConsequence
          soilAndVegetationConsequence
          ecosytemFunctionConsequence
          naturalConsequenceRiskScore
          naturalConsequenceRiskLevel
          totalRisk
          totalRiskScore
          totalRiskLevel
          vulnerabilityRanking
          objective
          requiredInformation
          target
          includeInPlan
          updatedAt
          createdAt
          owner
          region
        }
        indicators {
          nextToken
        }
        adaptationAction
        leadDepartment
        supportingDepartment
        justification
        includeInPlan
        scope
        supportingActions {
          nextToken
        }
        timeline
        costs
        additionalResources
        funding
        notes
        status
        implementedBy
        actualCosts
        monitoringNotes
        updatedAt
        createdAt
        owner
        region
      }
      action
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createIndicator = /* GraphQL */ `
  mutation CreateIndicator(
    $input: CreateIndicatorInput!
    $condition: ModelIndicatorConditionInput
  ) {
    createIndicator(input: $input, condition: $condition) {
      id
      adaptationAction {
        id
        climateImpact {
          id
          climateConditionId
          outcome
          consequence
          statement
          affectedAreas
          sensitivity
          sensitivityJustification
          adaptiveCapacity
          adaptiveCapacityJustification
          carryForward
          type
          likelihood
          likelihoodJustification
          publicHealthConsequence
          displacementConsequence
          lossOfLivelihoodConsequence
          culturalAspectsConsequence
          socialConsequenceRiskScore
          socialConsequenceRiskLevel
          propertyDamageConsequence
          localEconomyConsequence
          communityLivabilityConsequence
          publicAdministrationConsequence
          economicConsequenceRiskScore
          economicConsequenceRiskLevel
          airConsequence
          waterConsequence
          soilAndVegetationConsequence
          ecosytemFunctionConsequence
          naturalConsequenceRiskScore
          naturalConsequenceRiskLevel
          totalRisk
          totalRiskScore
          totalRiskLevel
          vulnerabilityRanking
          objective
          requiredInformation
          target
          includeInPlan
          updatedAt
          createdAt
          owner
          region
        }
        indicators {
          nextToken
        }
        adaptationAction
        leadDepartment
        supportingDepartment
        justification
        includeInPlan
        scope
        supportingActions {
          nextToken
        }
        timeline
        costs
        additionalResources
        funding
        notes
        status
        implementedBy
        actualCosts
        monitoringNotes
        updatedAt
        createdAt
        owner
        region
      }
      name
      willInformBaseline
      requiredData
      dataExists
      responsibleParty
      nextSteps
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateIndicator = /* GraphQL */ `
  mutation UpdateIndicator(
    $input: UpdateIndicatorInput!
    $condition: ModelIndicatorConditionInput
  ) {
    updateIndicator(input: $input, condition: $condition) {
      id
      adaptationAction {
        id
        climateImpact {
          id
          climateConditionId
          outcome
          consequence
          statement
          affectedAreas
          sensitivity
          sensitivityJustification
          adaptiveCapacity
          adaptiveCapacityJustification
          carryForward
          type
          likelihood
          likelihoodJustification
          publicHealthConsequence
          displacementConsequence
          lossOfLivelihoodConsequence
          culturalAspectsConsequence
          socialConsequenceRiskScore
          socialConsequenceRiskLevel
          propertyDamageConsequence
          localEconomyConsequence
          communityLivabilityConsequence
          publicAdministrationConsequence
          economicConsequenceRiskScore
          economicConsequenceRiskLevel
          airConsequence
          waterConsequence
          soilAndVegetationConsequence
          ecosytemFunctionConsequence
          naturalConsequenceRiskScore
          naturalConsequenceRiskLevel
          totalRisk
          totalRiskScore
          totalRiskLevel
          vulnerabilityRanking
          objective
          requiredInformation
          target
          includeInPlan
          updatedAt
          createdAt
          owner
          region
        }
        indicators {
          nextToken
        }
        adaptationAction
        leadDepartment
        supportingDepartment
        justification
        includeInPlan
        scope
        supportingActions {
          nextToken
        }
        timeline
        costs
        additionalResources
        funding
        notes
        status
        implementedBy
        actualCosts
        monitoringNotes
        updatedAt
        createdAt
        owner
        region
      }
      name
      willInformBaseline
      requiredData
      dataExists
      responsibleParty
      nextSteps
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteIndicator = /* GraphQL */ `
  mutation DeleteIndicator(
    $input: DeleteIndicatorInput!
    $condition: ModelIndicatorConditionInput
  ) {
    deleteIndicator(input: $input, condition: $condition) {
      id
      adaptationAction {
        id
        climateImpact {
          id
          climateConditionId
          outcome
          consequence
          statement
          affectedAreas
          sensitivity
          sensitivityJustification
          adaptiveCapacity
          adaptiveCapacityJustification
          carryForward
          type
          likelihood
          likelihoodJustification
          publicHealthConsequence
          displacementConsequence
          lossOfLivelihoodConsequence
          culturalAspectsConsequence
          socialConsequenceRiskScore
          socialConsequenceRiskLevel
          propertyDamageConsequence
          localEconomyConsequence
          communityLivabilityConsequence
          publicAdministrationConsequence
          economicConsequenceRiskScore
          economicConsequenceRiskLevel
          airConsequence
          waterConsequence
          soilAndVegetationConsequence
          ecosytemFunctionConsequence
          naturalConsequenceRiskScore
          naturalConsequenceRiskLevel
          totalRisk
          totalRiskScore
          totalRiskLevel
          vulnerabilityRanking
          objective
          requiredInformation
          target
          includeInPlan
          updatedAt
          createdAt
          owner
          region
        }
        indicators {
          nextToken
        }
        adaptationAction
        leadDepartment
        supportingDepartment
        justification
        includeInPlan
        scope
        supportingActions {
          nextToken
        }
        timeline
        costs
        additionalResources
        funding
        notes
        status
        implementedBy
        actualCosts
        monitoringNotes
        updatedAt
        createdAt
        owner
        region
      }
      name
      willInformBaseline
      requiredData
      dataExists
      responsibleParty
      nextSteps
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      name
      size
      folder
      key
      type
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      name
      size
      folder
      key
      type
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      name
      size
      folder
      key
      type
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createImplementationSurvey = /* GraphQL */ `
  mutation CreateImplementationSurvey(
    $input: CreateImplementationSurveyInput!
    $condition: ModelImplementationSurveyConditionInput
  ) {
    createImplementationSurvey(input: $input, condition: $condition) {
      id
      factor1
      factor2
      factor3
      factor4
      factor5
      factor6
      factor7
      factor8
      factor9
      factor10
      factor11
      factor12
      factor13
      factor14
      factor15
      factor16
      factor17
      factor18
      factor19
      factor20
      factor21
      factor22
      factor23
      factor24
      factor25
      localActionPlanTotal
      communityContextTotal
      managementAndGovernanceTotal
      staffAndFinancialResourcesTotal
      stakeholderManagementTotal
      version
      dateCompleted
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateImplementationSurvey = /* GraphQL */ `
  mutation UpdateImplementationSurvey(
    $input: UpdateImplementationSurveyInput!
    $condition: ModelImplementationSurveyConditionInput
  ) {
    updateImplementationSurvey(input: $input, condition: $condition) {
      id
      factor1
      factor2
      factor3
      factor4
      factor5
      factor6
      factor7
      factor8
      factor9
      factor10
      factor11
      factor12
      factor13
      factor14
      factor15
      factor16
      factor17
      factor18
      factor19
      factor20
      factor21
      factor22
      factor23
      factor24
      factor25
      localActionPlanTotal
      communityContextTotal
      managementAndGovernanceTotal
      staffAndFinancialResourcesTotal
      stakeholderManagementTotal
      version
      dateCompleted
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteImplementationSurvey = /* GraphQL */ `
  mutation DeleteImplementationSurvey(
    $input: DeleteImplementationSurveyInput!
    $condition: ModelImplementationSurveyConditionInput
  ) {
    deleteImplementationSurvey(input: $input, condition: $condition) {
      id
      factor1
      factor2
      factor3
      factor4
      factor5
      factor6
      factor7
      factor8
      factor9
      factor10
      factor11
      factor12
      factor13
      factor14
      factor15
      factor16
      factor17
      factor18
      factor19
      factor20
      factor21
      factor22
      factor23
      factor24
      factor25
      localActionPlanTotal
      communityContextTotal
      managementAndGovernanceTotal
      staffAndFinancialResourcesTotal
      stakeholderManagementTotal
      version
      dateCompleted
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createAccomplishment = /* GraphQL */ `
  mutation CreateAccomplishment(
    $input: CreateAccomplishmentInput!
    $condition: ModelAccomplishmentConditionInput
  ) {
    createAccomplishment(input: $input, condition: $condition) {
      id
      stakeholder
      communication
      instrument
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateAccomplishment = /* GraphQL */ `
  mutation UpdateAccomplishment(
    $input: UpdateAccomplishmentInput!
    $condition: ModelAccomplishmentConditionInput
  ) {
    updateAccomplishment(input: $input, condition: $condition) {
      id
      stakeholder
      communication
      instrument
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteAccomplishment = /* GraphQL */ `
  mutation DeleteAccomplishment(
    $input: DeleteAccomplishmentInput!
    $condition: ModelAccomplishmentConditionInput
  ) {
    deleteAccomplishment(input: $input, condition: $condition) {
      id
      stakeholder
      communication
      instrument
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      firstName
      lastName
      phone
      updatedAt
      createdAt
      owner
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      firstName
      lastName
      phone
      updatedAt
      createdAt
      owner
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      firstName
      lastName
      phone
      updatedAt
      createdAt
      owner
    }
  }
`;
export const createRegion = /* GraphQL */ `
  mutation CreateRegion(
    $input: CreateRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    createRegion(input: $input, condition: $condition) {
      id
      name
      population
      latitude
      longitude
      gcomId
      gcomOptIn
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateRegion = /* GraphQL */ `
  mutation UpdateRegion(
    $input: UpdateRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    updateRegion(input: $input, condition: $condition) {
      id
      name
      population
      latitude
      longitude
      gcomId
      gcomOptIn
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteRegion = /* GraphQL */ `
  mutation DeleteRegion(
    $input: DeleteRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    deleteRegion(input: $input, condition: $condition) {
      id
      name
      population
      latitude
      longitude
      gcomId
      gcomOptIn
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createAnnualReportQuestion = /* GraphQL */ `
  mutation CreateAnnualReportQuestion(
    $input: CreateAnnualReportQuestionInput!
    $condition: ModelAnnualReportQuestionConditionInput
  ) {
    createAnnualReportQuestion(input: $input, condition: $condition) {
      id
      year
      questionNumber
      columnNumber
      rowNumber
      answer
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateAnnualReportQuestion = /* GraphQL */ `
  mutation UpdateAnnualReportQuestion(
    $input: UpdateAnnualReportQuestionInput!
    $condition: ModelAnnualReportQuestionConditionInput
  ) {
    updateAnnualReportQuestion(input: $input, condition: $condition) {
      id
      year
      questionNumber
      columnNumber
      rowNumber
      answer
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteAnnualReportQuestion = /* GraphQL */ `
  mutation DeleteAnnualReportQuestion(
    $input: DeleteAnnualReportQuestionInput!
    $condition: ModelAnnualReportQuestionConditionInput
  ) {
    deleteAnnualReportQuestion(input: $input, condition: $condition) {
      id
      year
      questionNumber
      columnNumber
      rowNumber
      answer
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createAnnualReportStatus = /* GraphQL */ `
  mutation CreateAnnualReportStatus(
    $input: CreateAnnualReportStatusInput!
    $condition: ModelAnnualReportStatusConditionInput
  ) {
    createAnnualReportStatus(input: $input, condition: $condition) {
      id
      year
      status
      confirmation
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateAnnualReportStatus = /* GraphQL */ `
  mutation UpdateAnnualReportStatus(
    $input: UpdateAnnualReportStatusInput!
    $condition: ModelAnnualReportStatusConditionInput
  ) {
    updateAnnualReportStatus(input: $input, condition: $condition) {
      id
      year
      status
      confirmation
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteAnnualReportStatus = /* GraphQL */ `
  mutation DeleteAnnualReportStatus(
    $input: DeleteAnnualReportStatusInput!
    $condition: ModelAnnualReportStatusConditionInput
  ) {
    deleteAnnualReportStatus(input: $input, condition: $condition) {
      id
      year
      status
      confirmation
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const createBadgeStatus = /* GraphQL */ `
  mutation CreateBadgeStatus(
    $input: CreateBadgeStatusInput!
    $condition: ModelBadgeStatusConditionInput
  ) {
    createBadgeStatus(input: $input, condition: $condition) {
      id
      year
      name
      status
      confirmation
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const updateBadgeStatus = /* GraphQL */ `
  mutation UpdateBadgeStatus(
    $input: UpdateBadgeStatusInput!
    $condition: ModelBadgeStatusConditionInput
  ) {
    updateBadgeStatus(input: $input, condition: $condition) {
      id
      year
      name
      status
      confirmation
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
export const deleteBadgeStatus = /* GraphQL */ `
  mutation DeleteBadgeStatus(
    $input: DeleteBadgeStatusInput!
    $condition: ModelBadgeStatusConditionInput
  ) {
    deleteBadgeStatus(input: $input, condition: $condition) {
      id
      year
      name
      status
      confirmation
      updatedAt
      createdAt
      owner
      region
    }
  }
`;
