import React from 'react';
import { useParams } from 'react-router-dom';

import content from '../../content';

export default function WithContent({ id, Component, ...rest }) {
  const { locale } = useParams();
  return (
    <Component
      {...content[`${locale}/${id}`]}
      {...rest}
    />
  );
}
