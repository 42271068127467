import React, { Component } from 'react';
import { API, graphqlOperation }  from 'aws-amplify';
import { UserContext } from 'hooks/userContext';

function withDataWrapper(WrappedComponent, { getQueryVariables, beforeQuery, queries, onCreate, onUpdate, onDelete }) {
  return class WithData extends Component {
    static contextType = UserContext;

    constructor(props) {
      super(props);

      this.editable = {};
      
      if (typeof onCreate !== 'undefined') {
        this.editable.onRowAdd = (data) => {
          return this.createData(data);
        }
      }

      if (typeof onUpdate !== 'undefined') {
        this.editable.onRowUpdate = (data, oldData) => {
          return this.updateData(data, oldData);
        }
      }

      if (typeof onDelete !== 'undefined') {
        this.editable.onRowDelete = (data) => {
          return this.deleteData(data);
        }
      }

      this.fetchData = this._fetchData.bind(this);
      this.createData = this._createData.bind(this);
      this.updateData = this._updateData.bind(this);
      this.deleteData = this._deleteData.bind(this);
      this.fetchItemsNextToken = this._fetchItemsNextToken.bind(this);

      this.state = {
        loading: true,
        errors: [],
        showSnackBar: true
      };
    }

    async componentDidMount() {
      this.setState({ region: this.context.region });
      this.fetchData();
    }

    async componentDidUpdate() {
      if (this.state.region !== this.context.region) {
        this.setState({ region: this.context.region });
        this.fetchData();
      }
    }

    async _fetchItemsNextToken({ query, variables, items = [], callback = undefined }) {
      const { data } = await API.graphql(graphqlOperation(query, variables));
      const key = Object.keys(data).find(k => k.includes('list'));
      const res = data[key]; // res = { items: [], nextToken: '' }
    
      items.push(...res.items);
      if (callback) {
        callback(res.items);
      }
      if (!res.nextToken) return items;
    
      // eslint-disable-next-line no-param-reassign
      variables.nextToken = res.nextToken;
      return this.fetchItemsNextToken({ query, variables, items, callback });
    }

    async _fetchData() {
      this.setState({ loading: true });
      const { owner, region } = this.context;
      
      const defaultVariables = { owner, region };
      const defaultFilter = region ? {
        region: {
          eq: region
        }
      } : null;
      const queryKeys = Object.keys(queries);
      // loop through all the queries and run them separately in parallel
      const fetchDataPromises = queryKeys.map(queryKey => {
        const queryVariables = getQueryVariables ? getQueryVariables(this.props, queryKey) : {};
        const filter = defaultFilter || queryVariables.filter ? { filter: { ...defaultFilter, ...queryVariables.filter } } : {}
        const prevariables = { ...defaultVariables, ...queryVariables, ...filter };
        const variables = beforeQuery ? beforeQuery(prevariables) : prevariables

        return this.fetchItemsNextToken({ query: queries[queryKey], variables }).then(items => {
          return {
            [queryKey]: { items }
          };
        });
      });

      return Promise.all(fetchDataPromises).then(allItems => {
        // now put all the results back as though it was one big query
        const data = allItems.reduce((obj, item) => {
          return { ...obj, ...item };
        });
        window.fetchData = data;
        this.setState({ data, errors: [], loading: false });
      }).catch(err => {
        const { errors } = err;
        window.fetchData = errors;
        this.setState({ errors, loading: false });
      });
    }

    async _createData(data) {
      const mutation = onCreate(data, this.props);
      const { region } = this.context;
      
      // an array of inputs can be passed in and we will loop through them
      const inputs = Array.isArray(mutation[1]) ? mutation[1] : [mutation[1]];

      return Promise.all(inputs.map(_input => {
        const inputWithRegion = {
          ..._input,
          region
        };
  
        const input = beforeQuery ? beforeQuery(inputWithRegion) : inputWithRegion
        
        return API.graphql(graphqlOperation(mutation[0], { input })).then((response) => {
          window.createData = response;
        });
      })).then(() => {
        return this.fetchData();
      }).catch(err => {
        const { errors } = err;
        window.createData = errors;
        this.setState({ errors, loading: false });
      });
    }

    async _updateData(data, oldData) {
      const mutation = onUpdate(data, oldData, this.props);
      
      // an array of inputs can be passed in and we will loop through them
      const inputs = Array.isArray(mutation[1]) ? mutation[1] : [mutation[1]];

      const promises = inputs.map(input => {
        return API.graphql(graphqlOperation(mutation[0], { input }));
      });

      const { region } = this.context;
      // in some situations we need to also be able to create new records as a part of an update
      if (mutation[2] && mutation[3]) {
        
        const createInputs = Array.isArray(mutation[3]) ? mutation[3] : [mutation[3]];
        createInputs.forEach(input => {
          promises.push(API.graphql(graphqlOperation(mutation[2], { input: { ...input, region } })));
        });
      }

      return Promise.all(promises).then(() => {
        return this.fetchData();
      }).catch(err => {
        const { errors } = err;
        window.updateData = errors;
        this.setState({ errors, loading: false });
      });
    }

    async _deleteData(data) {
      const mutation = onDelete(data, this.props);
      
      // an array of inputs can be passed in and we will loop through them
      const inputs = Array.isArray(mutation[1]) ? mutation[1] : [mutation[1]];

      return Promise.all(inputs.map(input => {
        return API.graphql(graphqlOperation(mutation[0], { input })).then((response) => {
          window.deleteData = response;
        });
      })).then(() => {
        return this.fetchData();
      }).catch(err => {
        const { errors } = err;
        window.deleteData = errors;
        this.setState({ errors, loading: false });
      });
    }

    render() {
      const { data, loading, errors } = this.state;
      const { editable, createData, fetchData, updateData, deleteData } = this;
      
      return(
        <div>
          {data && (
            <WrappedComponent
              actions={{ createData, fetchData, updateData, deleteData }}
              data={data}
              editable={editable}
              errors={errors}
              loading={loading}
              {...this.props}
            />
          )}
        </div>
      );
    }
  }
}

export default withDataWrapper;
