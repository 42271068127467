import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from 'components';
import { Menu, MenuItem } from '@material-ui/core';
import { UserContext } from 'hooks/userContext';
import useGroups from 'hooks/useGroups';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.contrastText,
    margin: '5px'
  }
}));

const SelectRegionButton = props => {
  const classes = useStyles();
  
  const [anchorEl, setAnchorEl] = useState(null);
  const { region, setCurrentRegion } = useContext(UserContext);
  const { groups } = useGroups();
  
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickGroup = (group) => {
    setCurrentRegion(group);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  }

  const groupsMap = (group, index) => <MenuItem key={`region-${group}`} onClick={() => { onClickGroup(group)}}>{group}</MenuItem>

  return (
    <>
      <Button 
      className={clsx([classes.root])}
      aria-controls="simple-menu" aria-haspopup="true" onClick={openMenu}>
        {region}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {groups.map(groupsMap)}
      </Menu>
    </>
  );
};

export default SelectRegionButton;
