import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import { Questions, Markdown } from 'components';

const useStyles = makeStyles(theme => ({
  about: {
    marginTop: theme.spacing(3)
  }
}));

const Header = (props) => {
  const classes = useStyles();
  const { preheading, heading, contents, questions, aboutThisWorksheet } = props;
  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          variant="overline"
        >
          {preheading}
        </Typography>
        <Typography
          variant="h3"
        >
          {heading}
        </Typography>
        <Card
          className={clsx(classes.about)}
        >
          <CardHeader title={aboutThisWorksheet} />
          <CardContent
            className={clsx(classes.contents)}
          >
            <Markdown contents={contents} />
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
      >
        {questions && (
          <Questions questions={questions} />
        )}
      </Grid>
    </Grid>
  );
};

export default Header;
