import React from 'react';

import { Grid } from '@material-ui/core';
import { DataList } from 'components';
import DetailPanel from './DetailPanel';

const Example = (props) => {
  const options = {
    ...props.options,
    exportButton: false
  };

  const detailPanel = props.detailPanel ? DetailPanel : null;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <DataList
          {...props}
          options={options}
          detailPanel={detailPanel} 
        />
      </Grid>
    </Grid>
  );
}

export default Example;
