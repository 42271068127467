import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const Notifications = props => {
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  function handleUndo() {

  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">An Error has occured</span>}
      action={[
        <Button key="undo" color="secondary" size="small" onClick={handleUndo}>
          UNDO
        </Button>,
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ]}
    />
  );
}

export default Notifications;
