import React from 'react';
import { WithContent } from 'components';
import Header from './Header';


const HeaderWithContent = (props) => {
  return (
    <WithContent
      id='header.html'
      Component={Header}
      {...props} 
    />
  );
}

export default HeaderWithContent;
