import React, { Fragment } from 'react';
import {
  CardContent
} from '@material-ui/core';

import DataListItem from '../DataListItem';
import EditDataListItem from '../EditDataListItem';

const DataListCardContent = props => {
  const { id, edit, columns, data, values, handleFieldChange, detailPanel } = props;

  return (
    <CardContent>
      {columns && (
        columns.map((column, index) => {
          const editable = edit && (!column.editable || column.editable === 'onUpdate');
          return (
            <Fragment key={`content-${id}-${index}`}>
              {editable && (
                <EditDataListItem
                  column={column}
                  values={values}
                  handleFieldChange={handleFieldChange}
                />
              )}
              {!editable && (
                <DataListItem
                  column={column}
                  data={data}
                />
              )}
            </Fragment>
          );
        })
      )}
      {detailPanel && (
        detailPanel(data)
      )}
    </CardContent>
  );
};

export default DataListCardContent;
