import React from 'react';
import { Button } from 'components';

const AuthenticationLinks = ({ buttons, className }) => {
  return (
    <div>
      {buttons && buttons.map((button, index) => {
        return <Button
          className={className}
          key={`authentication-link-${index}`}
          {...button}
        />
      })}
    </div>
  );
};

export default AuthenticationLinks;
