import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';

import { SidebarNav, UpgradePlan } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 400,
    [theme.breakpoints.up('lg')]: {
      marginTop: 100,
      height: 'calc(100% - 100px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  milestones: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, pageGroups, milestones, contents, showSidebar } = props;

  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        className={clsx(classes.root, className)}
      >
        {milestones && (
          <SidebarNav
            className={classes.milestones}
            {...milestones}
          />
        )}
        {showSidebar && pageGroups && pageGroups.map((pageGroup, index) => {
          return <SidebarNav
            className={classes.nav}
            {...pageGroup}
            key={`navgroup-${index}`}
          />
        })}
        {showSidebar && (
          <UpgradePlan contents={contents} />
        )}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
