import React from 'react';
import { WithContent } from 'components';
import FilesDropzone from './FilesDropzone';


const FilesDropzoneWithContent = (props) => {
  return (
    <WithContent
      id='files/files-dropzone.html'
      Component={FilesDropzone}
      {...props} 
    />
  );
}

export default FilesDropzoneWithContent;
