import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Divider
} from '@material-ui/core';
import DateFnsAdapter from "@date-io/date-fns";

const useStyles = makeStyles(theme => ({
  root: {},
  dataGroup: {
    marginBottom: theme.spacing(1)
  },
  heading: {
    marginBottom: theme.spacing(1)
  },
  body: {
    marginBottom: theme.spacing(1)
  }
}));

const dateFns = new DateFnsAdapter();

const DataListItem = ({ column, data }) => {
  const classes = useStyles();
  const fieldValue = typeof data !== 'undefined' ? data[column.field] : null;
  const value = (column.lookup && fieldValue) ? column.lookup[fieldValue] : column.dateFormat ? dateFns.date(fieldValue) : fieldValue;

  return (
    <div className={classes.dataGroup}>
      <Typography variant="h6" className={classes.heading}>
        {column.title}
      </Typography>
      {column.dateFormat && value && (
        <Typography variant="body1" className={classes.body}>
          {dateFns.format(value, "MM/dd/yyyy")}
        </Typography>
      )}
      {!column.dateFormat && (
        <Typography variant="body1" className={classes.body}>
          {value}
        </Typography>  
      )}
      
      <Divider />
    </div>
  );
}

export default DataListItem;
