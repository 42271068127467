import React, { forwardRef } from 'react';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.contrastText,
    margin: '5px'
  },
  milestone1: {
    backgroundColor: theme.palette.milestone1.background
  },
  milestone2: {
    backgroundColor: theme.palette.milestone2.background
  },
  milestone3: {
    backgroundColor: theme.palette.milestone3.background
  },
  milestone4: {
    backgroundColor: theme.palette.milestone4.background
  },
  milestone5: {
    backgroundColor: theme.palette.milestone5.background
  },
  'annual-report': {
    backgroundColor: theme.palette['annual-report'].background
  }
}));

const MilestoneButton = props => {
  const classes = useStyles();
  const { milestone } = useParams();

  const CustomRouterLink = forwardRef((props, ref) => (
    <div
      ref={ref}
    >
      <RouterLink {...props} />
    </div>
  ));

  return (
    <Button
      className={clsx([
        classes.root,
        {
          [classes.active]: milestone === props.milestone
        },
        classes[props.milestone],
        `topbar__milestone-button--${milestone}`
      ]
      )}
      component={CustomRouterLink}
      to={props.href}
    >
      {props.children}
    </Button>
  );
};

export default MilestoneButton;
