import React from 'react';
import { useParams, Redirect } from 'react-router-dom';

export default function RouteWithRedirect({ to }) {
  const { locale } = useParams();

  return (
    <Redirect to={`/${locale}${to}`} />
  );
}
