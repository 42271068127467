import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Card, CardContent } from '@material-ui/core';
import { Markdown } from 'components';

const useStyles = makeStyles(theme => ({
  contents: {
    '& p': {
      color: theme.typography.body1
    }
  }
}));

const ComingSoon = ({ preheading, heading, contents }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          variant="overline"
        >
          {preheading}
        </Typography>
        <Typography
          variant="h3"
        >
          {heading}
        </Typography>
        <Card
          className={clsx(classes.about)}
        >
          <CardContent
            className={clsx(classes.contents)}
          >
            <Markdown contents={contents} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ComingSoon;
