import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { Container, Grid } from '@material-ui/core';

import { Topbar, Footer } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  content: {
    height: '100%'
  }
}));

const Main = props => {
  const { route } = props;
  
  const classes = useStyles();

  return (
    <div
      className={classes.root}
    >
      <Topbar />
      <main className={classes.content}>
        <Container>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={12}
            >
              <Suspense fallback={<LinearProgress />}>
                {renderRoutes(route.routes)}
              </Suspense>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </main>
    </div>
  );
};

export default Main;
