import React, { Suspense, useEffect, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Cookies from 'js-cookie'
import { makeStyles, useTheme } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { API, graphqlOperation }  from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import { UserContext } from 'hooks/userContext';

import { Sidebar, Topbar, Footer } from 'components';
import { listRegions } from 'graphql/queries';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 92,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 100
    }
  },
  shiftContent: {
    paddingLeft: 400
  },
  content: {
    height: '100%',
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  acknowledgement: {
    textAlign: 'center'
  }
}));

const Worksheet = props => {
  const { route, authData } = props;
  const { showSidebar = true } = route;
  
  const cookieRegion = Cookies.get('currentRegion');
  const [currentRegion, setCurrentRegion] = useState(cookieRegion || authData.signInUserSession.accessToken.payload['cognito:groups'][0]);
  const [regionData, setRegionData] = useState(null);
  
  useEffect(() => {
    Cookies.set('currentRegion', currentRegion);
    setRegionData(null);
    
    API.graphql(graphqlOperation(listRegions, {
      filter: {
        region: { eq: currentRegion }
      }
    })).then((resp) => {
      if (resp.data.listRegions && resp.data.listRegions.items.length > 0) {
        setRegionData(resp.data.listRegions.items[0]);
      }
    });
  }, [currentRegion]);
  
  const userContext = {
    user: authData,
    owner: authData.username,
    region: currentRegion,
    regionData: regionData,
    groups: authData.signInUserSession.accessToken.payload['cognito:groups'],
    jwtToken: authData.signInUserSession.accessToken.jwtToken,
    setCurrentRegion,
    setRegionData
  };

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;
  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <UserContext.Provider value={userContext}>
        <Topbar onSidebarOpen={handleSidebarOpen} />
        <Sidebar
          onClose={handleSidebarClose}
          open={shouldOpenSidebar}
          variant={isDesktop ? 'persistent' : 'temporary'}
          showSidebar={showSidebar}
        />
        
        <main className={classes.content}>
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
            <Suspense fallback={<LinearProgress />}>
              {renderRoutes(route.routes, { userContext })}
            </Suspense>
          </Grid>
          <Footer />
        </main>
      </UserContext.Provider>
    </div>
  );
};

Worksheet.propTypes = {
  component: PropTypes.node
};

export default withAuthenticator(Worksheet, {
  includeGreetings: false
});
