import React, { Component } from 'react';
import { Storage }  from 'aws-amplify';
import { withData } from 'components';
import { listFiles } from 'graphql/queries';
import { createFile, deleteFile } from 'graphql/mutations';
import bytesToSize from 'utils/bytesToSize';
import { UserContext } from 'hooks/userContext';

class WithStorage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this._deleteFile = this.deleteFile.bind(this);
    this._getDownloadUrl = this.getDownloadUrl.bind(this);
    this._uploadFiles = this.uploadFiles.bind(this);

    this.state = {
      errors: []
    };
  }

  async uploadFiles({ files }) {
    const { actions, folder } = this.props;
    const { region } = this.context;

    files.forEach(file => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        return Storage.put(`${region}/${folder}/${file.name}`, binaryStr, {
          contentType: file.type
        }).then(result => {
          const data = {
            name: file.name,
            size: bytesToSize(file.size),
            key: result.key,
            type: file.type,
            folder
          };
          return actions.createData(data);
        }).catch(err => {
          console.log(err);
        });
      }
      reader.readAsArrayBuffer(file);
    });
  }

  async getDownloadUrl(key) {
    return Storage.get(key)
      .then(result => {
        return result;
      })
      .catch(err => {
        console.log(err);
      });
  }

  async deleteFile(file) {
    const { actions } = this.props;
    const { id, key } = file;
    return Storage.remove(key)
      .then(result => {
        const data = {
          id
        };
        return actions.deleteData(data);
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { _uploadFiles, _fetchFiles, _getDownloadUrl, _deleteFile } = this;
    const { data, WrappedComponent } = this.props;
    const files = data.listFiles.items;
    return(
      <div>
        {files && (
          <WrappedComponent
            actions={{ uploadFiles: _uploadFiles, fetchFiles: _fetchFiles, getDownloadUrl: _getDownloadUrl, deleteFile: _deleteFile }}
            files={files}
          />
        )}
      </div>
    );
  }
}

const onCreate = (data) => {
  const input = { ...data };
  return [createFile, input];
};

const onDelete = (data) => {
  const input = {
    id: data.id
  };
  return [deleteFile, input];
};

const getQueryVariables = (props) => {
  return {
    filter: {
      folder: {
        eq: props.folder
      }
    }
  };
};

export default withData(WithStorage, {
  queries: {
    listFiles
  },
  getQueryVariables,
  onCreate,
  onDelete
});
