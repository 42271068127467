import React from 'react';

import { Example, WithContent } from 'components';

const DetailPanel = (props) => {
  return (
    <WithContent
      Component={Example}  
      id={props.detailPanel}
    /> 
  );
}

export default DetailPanel;
