import React from 'react';
import { Button } from '@material-ui/core';
import { signOut } from 'utils/getOwnerAndRegion';


const SignOut = ({ buttonText, className }) => {
  return (
    <Button
      className={className}
      data-test="sign-out-button"
      onClick={signOut}
    >
      {buttonText}
    </Button>
  );
};

export default SignOut;
