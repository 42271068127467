import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { MilestoneButton } from './components';
import { SignOut, AuthenticationLinks, Button } from 'components';
import useContent from 'hooks/useContent';
import SelectRegionButton from './components/SelectRegionButton';
import { UserContext } from 'hooks/userContext';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.contrastText,
    textTransform: 'uppercase'
  },
  userToolbar: {
    backgroundColor: theme.palette.secondary.dark,
    minHeight: '20px'
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  
  const context = useContext(UserContext);
  const { user, groups, regionData } = context;
  const classes = useStyles();
  
  const { title, links, settings } = useContent('topbar/topbar.html');

  const filteredLinks = links.filter(link => {
    let include = true;
    if (link.showIf) {
      include = regionData && !!regionData[link.showIf];
    }
    return include;
  });

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      {user && (
        <Toolbar
          className={classes.userToolbar}
        >
          <div className={classes.flexGrow} />
          {groups && groups.length > 1 && (
            <SelectRegionButton />
          )}
          <AuthenticationLinks className={classes.signOutButton} />
          <Button href="/settings">
            <Typography className={classes.signOutButton}>{settings}</Typography>
          </Button>
          <SignOut 
            className={classes.signOutButton}
          />
        </Toolbar>
      )}
      <Toolbar>
        <RouterLink to="/">
          <Typography variant="h1">{title}</Typography>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          {filteredLinks.map((link, index) => {
            return (
              <MilestoneButton
                {...link}
                key={`topnav-button-${index}`}
              >
                {link.text}
              </MilestoneButton>
            );
          })}
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
